import React, { useEffect, useState } from "react";
import { List, ShowButton } from "@refinedev/mui";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import { useNavigate } from "react-router-dom";
import { moneyFormat } from "utils/money-format";
import {
  Box,
  CircularProgress,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { InView } from "react-intersection-observer";
import SearchIcon from "@mui/icons-material/Search";
import { IGrantVestment } from "interfaces/grant-vestment";
import PaginatedEntityList from "interfaces/PaginatedEntityList";

export const GrantVestmentList: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const [grantVestmentData, setGrantVestmentData] = useState<IGrantVestment[]>(
    []
  );

  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState<string>("");
  const [limit] = useState(50);

  const { data, isSuccess, isFetching } = useCustom({
    url: `grant-vestments`,
    method: "get",
    config: {
      query: {
        keyword: keyword,
        limit: limit,
        offset: offset,
      },
    },
  });

  useEffect(() => {
    if (data) {
      if (
        (data.data as PaginatedEntityList<IGrantVestment>)?.pagination
          ?.offset === 0
      ) {
        setGrantVestmentData(
          (data.data as PaginatedEntityList<IGrantVestment>).data
        );
        const length = (data.data as PaginatedEntityList<IGrantVestment>).data
          .length;
        setOffset(length);
      } else {
        setGrantVestmentData([
          ...grantVestmentData,
          ...(data.data as PaginatedEntityList<IGrantVestment>).data,
        ]);
        setOffset(grantVestmentData.length);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const fetchData = (inView: boolean) => {
    if (inView && data?.data?.pagination.nextOffset) {
      // setOffset(categories?.data?.pagination.nextOffset);
    }
  };

  const clear = () => {
    setGrantVestmentData([]);
    setOffset(0);
  };
  const handleWordChange = (event: any) => {
    const { value } = event.target;
    if (event.key === "Enter") {
      clear();
      setKeyword(value);
    }
  };
  return (
    <>
      <List
        title="GrantVestment List"
        wrapperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,
          },
        }}
      >
        {isFetching ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Grid item xs={12} md={4} mb={1}>
              <FormControl variant="outlined" fullWidth>
                <FilledInput
                  className="search"
                  placeholder="Type keyword or name"
                  size="small"
                  id="keyword"
                  defaultValue={keyword}
                  name="keyword"
                  onChange={(event) =>
                    event?.target.value === "" && setKeyword("")
                  }
                  onKeyDown={(event) => handleWordChange(event)}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            {keyword && (
              <Grid margin={{ md: 1 }} my={1}>
                <FormControl size="small">
                  <OutlinedInput
                    value={keyword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setKeyword("");
                            clear();
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  ></OutlinedInput>
                </FormControl>
              </Grid>
            )}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell size="small" color="primary">
                      ID
                    </TableCell>
                    <TableCell color="primary" align="right">
                      Name
                    </TableCell>
                    <TableCell color="primary" align="right">
                      ProjectBy
                    </TableCell>
                    <TableCell color="primary" align="right">
                      Goal
                    </TableCell>
                    <TableCell color="primary" align="right">
                      Raised
                    </TableCell>
                    <TableCell color="primary" align="right">
                      Contributors
                    </TableCell>
                    <TableCell color="primary" align="right">
                      Status
                    </TableCell>
                    <TableCell color="primary" align="right">
                      All Or Nothing
                    </TableCell>
                    <TableCell color="primary" align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isSuccess && (
                    <>
                      {data &&
                        grantVestmentData.length > 0 &&
                        grantVestmentData.map((row: IGrantVestment) => (
                          <TableRow
                            key={row.grantVestmentId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{row.grantVestmentId}</TableCell>
                            <TableCell align="right">{row.name}</TableCell>
                            <TableCell align="right">{row.projectBy}</TableCell>
                            <TableCell align="right">
                              {moneyFormat(row.goalAmount)}
                            </TableCell>
                            <TableCell align="right">
                              {moneyFormat(row.raisedAmount)}
                            </TableCell>
                            <TableCell align="right">
                              {row.grantVestorCounter}
                            </TableCell>
                            <TableCell align="right">{row.status}</TableCell>

                            <TableCell align="right">
                              {row.allOrNothing ? "True" : "False"}
                            </TableCell>
                            <TableCell align="right">
                              <Grid container justifyContent={"flex-end"}>
                                <ShowButton
                                  hideText
                                  onClick={() =>
                                    navigate(
                                      `/grant-vestments/${row.grantVestmentId}`
                                    )
                                  }
                                />
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  )}

                  {isFetching ? (
                    <Grid
                      item
                      xs={12}
                      sm={10}
                      textAlign={"center"}
                      sx={{ m: 3 }}
                    >
                      {" "}
                      <CircularProgress color="primary" />
                    </Grid>
                  ) : data?.data?.pagination.hasNext ? (
                    <InView
                      as="div"
                      onChange={(inView) => fetchData(inView)}
                    ></InView>
                  ) : null}
                  {!isFetching && data?.data.length === 0 && (
                    <Typography variant="subtitle3">
                      No grantVestment found.
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </List>
    </>
  );
};
