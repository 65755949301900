import { DeleteButton, Edit } from "@refinedev/mui";
import { TextField, Box, CircularProgress } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export const UpdateCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    formState: { errors, isLoading },
  } = useForm({
    refineCoreProps: {
      resource: "categories",
      errorNotification: (error) => {
        return {
          message: error?.message?.includes("duplicate key value")
            ? "Category already exist"
            : error?.message?.includes("violates foreign key constraint")
            ? "The following category is used in the platform and can not be deleted!"
            : "Oops! something went wrong, please try later.",
          type: "error",
        };
      },
    },
    warnWhenUnsavedChanges: true,
  });

  return (
    <Edit
      isLoading={isLoading || formLoading}
      saveButtonProps={saveButtonProps}
      footerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          <DeleteButton
            recordItemId={id}
            variant="outlined"
            onSuccess={() => {
              navigate(`/categories`);
            }}
            errorNotification={(error: any) => {
              return {
                message: error?.message?.includes(
                  "violates foreign key constraint"
                )
                  ? "The following category is used in the platform and can not be deleted!"
                  : "Oops! something went wrong, please try later.",
                type: "error",
              };
            }}
          />
        </>
      )}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        {isLoading || formLoading ? (
          <CircularProgress />
        ) : (
          <form>
            <TextField
              {...register("categoryName", {
                required: "This category name is required",
              })}
              error={!!(errors as any)?.title}
              helperText={(errors as any)?.title?.message}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Category Name"
              name="categoryName"
            />
          </form>
        )}
      </Box>
    </Edit>
  );
};
