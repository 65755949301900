import React from "react";
import {
  useDataGrid,
  ShowButton,
  List,
  DateField,
  EmailField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps } from "@refinedev/core";
import { useNavigate } from "react-router-dom";

export const UserRequestList: React.FC<IResourceComponentsProps> = () => {
  const { dataGridProps } = useDataGrid();
  const navigate = useNavigate();
  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "firstName",
        flex: 1,
        headerName: "First Name",
        minWidth: 200,
      },
      {
        field: "lastName",
        flex: 1,
        headerName: "Last Name",
        minWidth: 200,
      },
      {
        field: "email",
        flex: 1,
        headerName: "Email",
        minWidth: 250,
        renderCell: function render({ value }) {
          return <EmailField value={value} />;
        },
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "createdAt",
        flex: 1,
        headerName: "Created At",
        minWidth: 250,
        renderCell: function render({ value }) {
          return <DateField value={value} />;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <ShowButton
                hideText
                onClick={() => navigate(`/users/status/${row.sub}`)}
              />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [navigate]
  );

  return (
    <List title="Users' Pending Requests">
      <DataGrid
        {...dataGridProps}
        getRowId={(row) => row.userId}
        columns={columns}
        autoHeight
      />
    </List>
  );
};
