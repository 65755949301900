export const getPercentage = (amount1: number, amount2: number) => {
  const funded = (amount1 / amount2) * 100;
  return funded.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};
export const moneyFormat = (amount: number, decimal?: number, min?: number) => {
  if (amount) {
    return amount.toLocaleString("en-US", {
      maximumFractionDigits: decimal ? decimal : 2,
      minimumFractionDigits: min === undefined ? 2 : 0,
    });
  }
  return 0;
};
export const unitFormat = (amount: number) => {
  if (amount) {
    return amount.toLocaleString("en-US", {
      maximumFractionDigits: 3,
      minimumFractionDigits: 1,
    });
  }
  return 0;
};
