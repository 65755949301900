import React from "react";
import { useDataGrid, List, DateField, EmailField } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps } from "@refinedev/core";

export const UserRejectedList: React.FC<IResourceComponentsProps> = () => {
  const { dataGridProps } = useDataGrid();
  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "firstName",
        flex: 1,
        headerName: "First Name",
        minWidth: 200,
      },
      {
        field: "lastName",
        flex: 1,
        headerName: "Last Name",
        minWidth: 200,
      },
      {
        field: "email",
        flex: 1,
        headerName: "Email",
        minWidth: 250,
        renderCell: function render({ value }) {
          return <EmailField value={value} />;
        },
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "dateOfBirth",
        flex: 1,
        headerName: "Date of birth",
        minWidth: 250,
        renderCell: function render({ value }) {
          return <DateField value={value} />;
        },
      },
      {
        field: "updatedAt",
        flex: 1,
        headerName: "Updated At",
        minWidth: 250,
        renderCell: function render({ value }) {
          return <DateField value={value} />;
        },
      },
    ],
    []
  );

  return (
    <List title="Rejected Users List">
      <DataGrid
        {...dataGridProps}
        getRowId={(row) => row.userId}
        columns={columns}
        autoHeight
      />
    </List>
  );
};
