import { createTheme } from "@mui/material/styles";
import { accent, errorAlert, neutral, primary, warm } from "./color";

import {
  ThemeOptions,
  filledInputClasses,
  menuItemClasses,
  stepIconClasses,
} from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    warm: Palette["primary"];
    neutral: Palette["primary"];
  }

  interface PaletteOptions {
    warm?: PaletteOptions["primary"];
    neutral?: PaletteOptions["primary"];
  }
}
declare module "@mui/material/styles" {
  interface PaletteColor {
    lighter?: string;
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    header: true;
    headeractive: true;
    footer: true;
  }
}
export const golbalTheme = createTheme({
  palette: {
    background: {
      default: warm[200],
    },
    mode: "light",
    primary: {
      main: primary[600],
      light: primary[200],
      dark: primary[800],
    },
    secondary: {
      main: "#FFF",
      dark: accent[600],
      contrastText: primary[600],
    },
    warm: {
      main: warm[500],
      light: warm[200],
      contrastText: neutral[900],
    },
    neutral: {
      main: neutral[900],
      dark: neutral[700],
      light: neutral[400],
      lighter: neutral[600],
    },
  },
  typography: {
    fontFamily: "Quattrocento Sans",
    button: {
      textTransform: "none",
    },
    h0: {
      fontFamily: "Source Serif 4",
      fontSize: "64px",
      fontWeight: "700",
      lineHeight: "64px",
    },
    h1: {
      fontFamily: "Source Serif 4",
      fontSize: "40px",
      fontWeight: "700",
      lineHeight: "44px",
    },
    h2: {
      fontFamily: "Quattrocento Sans",
      fontSize: "40px",
      fontWeight: "700",
      lineHeight: "44px",
    },
    h3: {
      fontFamily: "Quattrocento Sans",
      fontSize: "34px",
      fontWeight: "700",
      lineHeight: "37.67px",
    },
    subtitle1: {
      fontFamily: "Quattrocento Sans",
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "26.59px",
    },
    subtitle2: {
      fontFamily: "Quattrocento Sans",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "19.94px",
    },
    subtitle3: {
      fontFamily: "Quattrocento Sans",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "19.94px",
    },
    bodyCopyBold: {
      fontFamily: "Quattrocento Sans",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "17.73px",
    },
    bodyCopyRegular: {
      fontFamily: "Quattrocento Sans",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
    },
    smallCopyBold: {
      fontFamily: "Quattrocento Sans",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "15.51px",
    },
    smallCopy: {
      fontFamily: "Quattrocento Sans",
      fontSize: "12px",
      fontWeight: "700",
      lineHeight: "13.3px",
    },
  },
} as ThemeOptions);

export const theme = createTheme(
  {
    components: {
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            "&.hasText": {
              "&.Mui-expanded div": {
                marginBottom: 0,
              },
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            "&.hasText": {
              paddingTop: 0,
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderRadius: "8px",
            padding: "40px",
            margin: "16px 0!important",
            "&::before": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            "& .MuiStepIcon-text": {
              display: "none",
            },
            borderRadius: "50%",
            border: "2px solid",
            borderColor: golbalTheme.palette.primary.light,
            color: "transparent",
            [`&.${stepIconClasses.completed}`]: {
              color: golbalTheme.palette.primary.light,
            },
            [`&.${stepIconClasses.active}`]: {
              border: "none",
            },
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          alternativeLabel: {
            ".Mui-active": {
              border: "none",
            },
            ".Mui-disabled": {
              border: "none",
            },
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: golbalTheme.palette.neutral.light,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            "&.settings": {
              color: "#FFF",
              backgroundColor: golbalTheme.palette.secondary.dark,
              ...golbalTheme.typography.subtitle3,
              "& .MuiAlert-message": {
                width: "100%",
                display: "flex",
                justifyContent: "center",
              },
            },
            "&.error": {
              color: "#FFF",
              backgroundColor: errorAlert[500],
              ...golbalTheme.typography.smallCopyBold,
            },
          },
        },
      },
      MuiCollapse: {
        styleOverrides: {
          root: {
            "&.sidebar": {
              ...golbalTheme.typography.bodyCopyRegular,
              backgroundColor: golbalTheme.palette.primary.dark,
              color: primary[100],
              height: "100%",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "100px",
            ...golbalTheme.typography.bodyCopyBold,
            boxShadow: "none",
          },
        },
        variants: [
          {
            props: { className: "icon" },
            style: {
              backgroundColor: "transparent",
              "& span svg  path": {
                fill: primary[500],
              },
              "& span svg  circle": {
                stroke: primary[500],
              },
            },
          },
          {
            props: { variant: "primary" },
            style: {
              backgroundColor: golbalTheme.palette.primary.main,
              color: "#FFF",
            },
          },
          {
            props: { color: "primary", variant: "contained" },
            style: {
              "& .MuiButton-startIcon": {
                "&  svg  path": {
                  fill: "white",
                },
                "&  svg  circle": {
                  stroke: "white",
                },
              },
            },
          },
          {
            props: { color: "primary", variant: "outlined" },
            style: {
              "& .MuiButton-startIcon": {
                "&  svg  path": {
                  fill: primary[500],
                },
                "&  svg  circle": {
                  stroke: primary[500],
                },
              },
            },
          },
          {
            props: {
              color: "primary",
              variant: "text",
              className: "underline",
            },
            style: {
              textDecoration: "underline",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
              "& .MuiButton-startIcon": {
                "&  svg  path": {
                  fill: primary[500],
                },
                "&  svg  circle": {
                  stroke: primary[500],
                },
              },
            },
          },
          {
            props: { variant: "header" },
            style: {
              ...golbalTheme.typography.bodyCopyBold,
              backgroundColor: "transparent",
              color: golbalTheme.palette.neutral.main,
              display: "block",
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
          {
            props: { variant: "headeractive" },
            style: {
              ...golbalTheme.typography.bodyCopyBold,
              backgroundColor: "transparent",
              color: golbalTheme.palette.primary.main,
              display: "block",
              textDecoration: "underline",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            },
          },
          {
            props: { variant: "footer" },
            style: {
              ...golbalTheme.typography.smallCopyBold,
              backgroundColor: "transparent",
              color: golbalTheme.palette.neutral.main,
              display: "block",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            },
          },
          {
            props: { color: "secondary" },
            style: {
              backgroundColor: golbalTheme.palette.secondary.main,
              color: "#FFF",
            },
          },
        ],
      },
      /*  MuiFilledInput: {
        variants: [
          {
            props: { className: "search" },
            style: {
              ...golbalTheme.typography.bodyCopyRegular,
              backgroundColor: golbalTheme.palette.warm.main,
              color: golbalTheme.palette.neutral.dark,
              borderRadius: "30px",
              "& .MuiFilledInput-input": {
                paddingTop: "10px",
                paddingBottom: "10px",
              },
              "&::before": {
                border: "transparent",
              },
              "&::after": {
                border: "transparent",
              },
              "&:hover": {
                backgroundColor: golbalTheme.palette.warm.main,
              },
              [`&.${filledInputClasses.focused}`]: {
                backgroundColor: golbalTheme.palette.warm.main,
              },
              "&:hover:not(.Mui-disabled, .Mui-error):before": {
                border: "transparent",
              },
            },
          },
        ],
      }, */
      MuiIconButton: {
        variants: [
          {
            props: { className: "grantvenstment" },
            style: {
              "&:hover": {
                backgroundColor: "transparent",
                "& svg  path": {
                  fill: primary[500],
                },
                "& svg  circle": {
                  stroke: primary[500],
                },
              },
              "& svg  path": {
                fill: neutral[500],
              },
              "& svg  circle": {
                stroke: neutral[500],
              },
            },
          },
          {
            props: { className: "grantvenstment", color: "secondary" },
            style: {
              "&:hover": {
                backgroundColor: "transparent",
                "& svg  path": {
                  fill: golbalTheme.palette.secondary.dark,
                },
                "& svg  circle": {
                  stroke: golbalTheme.palette.secondary.dark,
                },
              },
              "& svg  path": {
                fill: neutral[500],
              },
              "& svg  circle": {
                stroke: neutral[500],
              },
            },
          },
        ],
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            "&.title": {
              textTransform: "uppercase",
            },
          },
        },
      },
      MuiCard: {
        variants: [
          {
            props: { className: "single" },
            style: {
              display: "flex",
              boxShadow: "none",
            },
          },
          {
            props: { className: "list" },
            style: {
              maxWidth: 430,
              "&:hover": {
                cursor: "pointer",
              },
            },
          },
        ],
      },
      /*      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              color: neutral[500],
              backgroundColor: neutral[100],
            },
            "&.search": {
              ...golbalTheme.typography.bodyCopyBold,
              borderColor: neutral[400],
              "& fieldset": {
                borderColor: neutral[400],
              },
              "&:hover fieldset": {
                borderColor: neutral[400],
              },
              "&.Mui-focused fieldset": {
                borderColor: neutral[400],
                borderWidth: "1px",
              },
              "& div": {
                display: "flex",
                alignItems: "center",
              },
            },
          },
          input: {
            "&[type=number]": {
              "-moz-appearance": "textfield",
            },
            "&::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "&::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          },
        },
      }, */
      MuiPaper: {
        styleOverrides: {
          root: {
            "&.search": {
              border: `1px solid ${neutral[400]}`,
              "& ul": {
                ...golbalTheme.typography.bodyCopyBold,
                color: neutral[900],

                backgroundColor: warm[200],
              },
              "& li": {
                [`&.${menuItemClasses.selected}`]: {
                  backgroundColor: warm[500],
                },
              },
            },
          },
        },
      },
      MuiTab: {
        variants: [
          {
            props: { className: "grantvestment" },
            style: {
              ...golbalTheme.typography.bodyCopyBold,
              marginRight: "10%",
            },
          },
        ],
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            "&.grantvestment": {
              borderBottom: `1px solid ${golbalTheme.palette.neutral.light}`,
            },
          },
        },
      },

      MuiBackdrop: {
        styleOverrides: {
          root: {
            "&.Dialog": {
              backgroundColor: `${neutral[900]}!important`,
              opacity: "50%!important",
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            "&.successor": {
              borderColor: neutral[400],
              borderRadius: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              padding: "0px",
            },
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            "&.successor-item": {
              "& button": {
                display: "none",
              },
              "&:hover": {
                "& button": {
                  display: "inline-flex",
                },
              },
            },
            "&.active": {
              borderLeft: "solid #FFFFFF 4px",
              "& .MuiListItemText-root span": {
                ...golbalTheme.typography.bodyCopyBold,
                color: "white",
              },
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: `${primary[50]}!important`,
            color: neutral[900],
          },
          label: {
            fontFamily: "Quattrocento Sans",
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "15.51px",
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          ul: {
            justifyContent: "center",
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            ...golbalTheme.typography.smallCopyBold,
            "&.Mui-selected": {
              backgroundColor: warm[400],
              fontWeight: 700,
            },
          },
        },
      },
      MuiFilledInput: {
        variants: [
          {
            props: { className: "search" },
            style: {
              ...golbalTheme.typography.bodyCopyRegular,
              backgroundColor: golbalTheme.palette.warm.main,
              color: golbalTheme.palette.neutral.dark,
              borderRadius: "30px",
              "& .MuiFilledInput-input": {
                paddingTop: "10px",
                paddingBottom: "10px",
              },
              "&::before": {
                border: "transparent",
              },
              "&::after": {
                border: "transparent",
              },
              "&:hover": {
                backgroundColor: golbalTheme.palette.warm.main,
              },
              [`&.${filledInputClasses.focused}`]: {
                backgroundColor: golbalTheme.palette.warm.main,
              },
              "&:hover:not(.Mui-disabled, .Mui-error):before": {
                border: "transparent",
              },
            },
          },
        ],
      },
    },
  },
  golbalTheme
);
