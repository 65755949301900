import React, { useState } from "react";
import { useDataGrid, List, ShowButton, EditButton } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import { Box, Button, CircularProgress } from "@mui/material";
import { moneyFormat } from "utils/money-format";
import { useNavigate } from "react-router-dom";
import { FeedModal } from "components/modal/fee-modal";

export const FundAccountList: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [fund, setFund] = useState({ fundName: "", fundId: 0 });

  const { dataGridProps } = useDataGrid();
  const { data, isLoading, refetch } = useCustom({
    url: `funds`,
    method: "get",
    config: {
      query: {},
    },
  });

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "fundAccountName",
        flex: 1,
        headerName: "Fund Name",
        minWidth: 100,
      },
      {
        field: "entityInformation",
        flex: 1,
        headerName: "Type",
        minWidth: 80,
        renderCell: ({ row }) => {
          if (row.entityInformation) {
            return <Button size="small">Entity</Button>;
          } else {
            return <Button size="small">Personal</Button>;
          }
        },
      },
      {
        field: "availableBalance",
        flex: 1,
        headerName: "Available Balance",
        minWidth: 100,
        renderCell: function render({ value }: any) {
          return <>${moneyFormat(value, 2)}</>;
        },
      },
      {
        field: "total",
        flex: 1,
        headerName: "Total",
        minWidth: 100,
        renderCell: function render({ value }: any) {
          return <>${moneyFormat(value, 2)}</>;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <ShowButton
                hideText
                onClick={() =>
                  navigate(`/fund-accounts/show/${row.fundAccountId}`)
                }
              />
              <EditButton
                hideText
                onClick={() => {
                  setFund({
                    fundId: row.fundAccountId,
                    fundName: row.fundAccountName,
                  });
                  setOpen(true);
                }}
              />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [navigate]
  );

  return (
    <>
      <List title="Fund Account List">
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <DataGrid
            {...dataGridProps}
            rows={data?.data?.data}
            getRowId={(row) => row.fundAccountId}
            columns={columns}
            autoHeight
          />
        )}
      </List>
      <FeedModal setOpen={setOpen} open={open} fund={fund} refetch={refetch} />
    </>
  );
};
