import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IResourceComponentsProps, useCustom, useShow } from "@refinedev/core";
import { IFundAccount } from "interfaces/fund-account";
import { useState } from "react";
import { neutral, warm } from "style/color";
import { moneyFormat } from "utils/money-format";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ICategory } from "interfaces/category";
import {
  CharityInfo,
  IndividualInfo,
  SUCCESSOR_TYPE_ENUM,
  ISuccessor,
} from "interfaces/successor";
import { MovementsCard } from "components/card/movements-card";
import { CustomTabPanel } from "components/panel/CustomTabPanel";
import { useNavigate } from "react-router-dom";

export const FundAccountDetails: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IFundAccount>();
  const { data } = queryResult;
  const [value, setValue] = useState(0);
  const { data: successorsData, isLoading: successorsLoading } = useCustom<
    ISuccessor[]
  >({
    url: `funds/${queryResult.data?.data.fundAccountId}/successors`,
    method: "get",

    queryOptions: {
      enabled: !!queryResult.data?.data.fundAccountId,
    },
  });
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      {queryResult?.status === "loading" ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Grid sx={{ backgroundColor: warm[50], my: 4 }}>
          <Box sx={{ mx: 3 }}>
            <Box sx={{ mx: 3 }}>
              <Grid sx={{ mb: 2, mt: 2 }} container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h3">
                    {data?.data.fundAccountName}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    p: 2,
                    pt: "37px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    my={2}
                    textAlign={"right"}
                    sx={{ display: "flex", gap: 2 }}
                  >
                    <Button variant="contained" color="primary" size="large">
                      {data?.data.entityInformation ? "Entity" : "Personal"}
                    </Button>
                  </Grid>
                  <Grid
                    sm={4}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        gap: 4,
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                        }}
                      >
                        <Typography variant="smallCopy" color="primary">
                          TOTAL BALANCE
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "18px",
                            lineHeight: "19.94px",
                          }}
                        >
                          {" "}
                          ${moneyFormat(data?.data.total!)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                        }}
                      >
                        <Typography variant="smallCopy" color="primary">
                          AVAILABLE BALANCE
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "18px",
                            lineHeight: "19.94px",
                          }}
                        >
                          ${moneyFormat(data?.data.availableBalance!)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Information" id={"information-tab"} />
                <Tab label="Users" id={"users-tab"} />
                <Tab label="Movements" id={"movement-tab"} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box>
                {data?.data.entityInformation?.entityName && (
                  <>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color={"primary"}
                    >
                      Entity information:
                    </Typography>
                    <TextField
                      value={
                        data?.data.entityInformation.entityName &&
                        data?.data.entityInformation.entityName
                      }
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Entity name"
                      name="entityInformation.entityName"
                      disabled
                    />
                    <TextField
                      value={
                        data?.data.entityInformation.type &&
                        data?.data.entityInformation.type
                      }
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Entity type"
                      disabled
                    />

                    <TextField
                      value={
                        data?.data.entityInformation.firstAddressLine &&
                        data?.data.entityInformation.firstAddressLine
                      }
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Entity Address "
                      disabled
                    />

                    <TextField
                      value={data?.data?.entityInformation.city}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="City"
                      disabled
                    />
                    <TextField
                      value={data?.data?.entityInformation.zip}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Zip"
                      disabled
                    />
                    <TextField
                      value={data?.data.entityInformation?.state}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="State"
                      disabled
                    />
                    {data?.data?.entityInformation.secondAddressLine && (
                      <TextField
                        value={data?.data?.entityInformation.secondAddressLine}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Entity second Address "
                        disabled
                      />
                    )}
                  </>
                )}
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color={"primary"}
                  sx={{ my: 1 }}
                >
                  Fund Interest:
                </Typography>
                {data?.data.interests && data?.data.interests.length > 0 ? (
                  <Grid sx={{ display: "flex" }} gap={1}>
                    {data?.data.interests?.map((category: ICategory) => (
                      <Grid item>
                        <Button
                          variant="outlined"
                          size="large"
                          color={"primary"}
                        >
                          {category.categoryName}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography variant="smallCopy">
                    No interest found.
                  </Typography>
                )}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color={"primary"}
                pb={2}
              >
                Fund Account Users:{" "}
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={3}>
                {data?.data.fundAccountMembers &&
                data?.data.fundAccountMembers?.length > 0 ? (
                  data?.data.fundAccountMembers?.map((fundAccountMember) => (
                    <Card
                      sx={{ backgroundColor: warm[200], boxShadow: "none" }}
                      onClick={() =>
                        navigate(`/users/show/${fundAccountMember.user.sub}`)
                      }
                    >
                      <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
                        <Stack direction="row" spacing={2}>
                          <Box>
                            <Typography
                              variant="subtitle3"
                              sx={{
                                color: neutral[700],
                                display: "flex",
                                alignItems: "center",
                              }}
                              gutterBottom
                            >
                              <Box sx={{ mr: 3 }}>{`${
                                fundAccountMember?.user.title
                                  ? fundAccountMember.user?.title
                                  : ""
                              } ${
                                fundAccountMember.user?.firstName
                                  ? fundAccountMember.user?.firstName
                                  : ""
                              } ${
                                fundAccountMember.user?.middleName
                                  ? fundAccountMember.user?.middleName
                                  : ""
                              }${
                                fundAccountMember.user?.lastName
                                  ? fundAccountMember.user?.lastName
                                  : ""
                              } ${
                                fundAccountMember.user?.suffix
                                  ? fundAccountMember.user?.suffix
                                  : ""
                              }`}</Box>
                            </Typography>
                            <Typography
                              variant="bodyCopyBold"
                              component={"div"}
                            >
                              {fundAccountMember.user.email}
                            </Typography>
                            <Typography
                              variant="bodyCopyRegular"
                              component={"div"}
                            >
                              {fundAccountMember.user.phoneNumber}
                            </Typography>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography
                    sx={{ color: neutral[600] }}
                    variant="bodyCopyRegular"
                  >
                    No user found.{" "}
                  </Typography>
                )}
              </Box>

              <Typography
                variant="body1"
                fontWeight="bold"
                py={2}
                color={"primary"}
              >
                Fund Account Successors:{" "}
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={3}>
                {successorsLoading && (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", my: 5 }}
                  >
                    <CircularProgress color="primary" />
                  </Box>
                )}
                {!successorsLoading &&
                successorsData?.data &&
                successorsData?.data.length > 0 ? (
                  successorsData?.data.map((successor) => (
                    <Card
                      sx={{
                        backgroundColor: warm[200],
                        boxShadow: "none",
                        minWidth: "400px",
                      }}
                    >
                      <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
                        <Stack direction="column">
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ alignSelf: "flex-end" }}
                          >
                            {successor.percentage}%
                          </Button>
                          <Stack direction="row" spacing={2}>
                            <Box>
                              <Typography
                                variant="subtitle3"
                                sx={{
                                  color: neutral[700],
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                gutterBottom
                              >
                                <Box sx={{ mr: 3 }}>
                                  {successor.successorType ===
                                  SUCCESSOR_TYPE_ENUM.INDIVIDUAL
                                    ? `${
                                        (
                                          successor.successorInfo as IndividualInfo
                                        ).title
                                          ? (
                                              successor.successorInfo as IndividualInfo
                                            ).title
                                          : ""
                                      } ${
                                        (
                                          successor.successorInfo as IndividualInfo
                                        ).firstName
                                          ? (
                                              successor.successorInfo as IndividualInfo
                                            ).firstName
                                          : ""
                                      } ${
                                        (
                                          successor.successorInfo as IndividualInfo
                                        ).middleName
                                          ? (
                                              successor.successorInfo as IndividualInfo
                                            ).middleName
                                          : ""
                                      }${
                                        (
                                          successor.successorInfo as IndividualInfo
                                        ).lastName
                                          ? (
                                              successor.successorInfo as IndividualInfo
                                            ).lastName
                                          : ""
                                      } ${
                                        (
                                          successor.successorInfo as IndividualInfo
                                        ).suffix
                                          ? (
                                              successor.successorInfo as IndividualInfo
                                            ).suffix
                                          : ""
                                      }`
                                    : (successor.successorInfo as CharityInfo)
                                        .name}
                                </Box>
                              </Typography>
                              {successor.successorType ===
                              SUCCESSOR_TYPE_ENUM.INDIVIDUAL ? (
                                <>
                                  <Typography
                                    variant="bodyCopyBold"
                                    component={"div"}
                                  >
                                    {
                                      (
                                        successor.successorInfo as IndividualInfo
                                      ).email
                                    }
                                  </Typography>
                                  <Typography
                                    variant="bodyCopyBold"
                                    component={"div"}
                                  >
                                    {
                                      (
                                        successor.successorInfo as IndividualInfo
                                      ).phoneNumber
                                    }
                                  </Typography>
                                  <Typography
                                    variant="bodyCopyBold"
                                    component={"div"}
                                  >
                                    {` ${
                                      (
                                        successor.successorInfo as IndividualInfo
                                      ).addressLine1
                                    }, ${
                                      (
                                        successor.successorInfo as IndividualInfo
                                      ).addressLine2
                                    }, ${
                                      (
                                        successor.successorInfo as IndividualInfo
                                      ).city
                                    }, ${
                                      (
                                        successor.successorInfo as IndividualInfo
                                      ).state
                                    }, ${
                                      (
                                        successor.successorInfo as IndividualInfo
                                      ).zip
                                    }`}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Typography
                                    variant="bodyCopyBold"
                                    component={"div"}
                                  >
                                    {
                                      (successor.successorInfo as CharityInfo)
                                        .ein
                                    }
                                  </Typography>
                                  <Typography
                                    variant="bodyCopyBold"
                                    component={"div"}
                                  >
                                    {
                                      (successor.successorInfo as CharityInfo)
                                        .specificInstruction
                                    }
                                  </Typography>
                                </>
                              )}
                            </Box>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography
                    sx={{ color: neutral[600] }}
                    variant="bodyCopyRegular"
                  >
                    No Succession plan found.{" "}
                  </Typography>
                )}
              </Box>
              <Typography
                variant="body1"
                fontWeight="bold"
                py={2}
                color={"primary"}
              >
                Fund Account Invitations:{" "}
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={3}>
                {data?.data.invitations &&
                data?.data.invitations?.length > 0 ? (
                  data?.data.invitations?.map((invitation) => (
                    <Card
                      sx={{ backgroundColor: warm[200], boxShadow: "none" }}
                    >
                      <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
                        <Stack direction="row" spacing={2}>
                          <Box>
                            <Typography
                              variant="bodyCopyBold"
                              sx={{
                                color: neutral[700],
                                display: "flex",
                                alignItems: "center",
                              }}
                              gutterBottom
                            >
                              <Box sx={{ mr: 3 }}>{invitation?.status}</Box>
                            </Typography>
                            <Typography variant="subtitle3">
                              {invitation.email}
                            </Typography>
                            <Typography
                              variant="bodyCopyRegular"
                              component={"div"}
                            >
                              Expired At:{invitation.expiredAt}
                            </Typography>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography
                    sx={{ color: neutral[600] }}
                    variant="bodyCopyRegular"
                  >
                    No invitations found.{" "}
                  </Typography>
                )}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <MovementsCard fundId={data?.data.fundAccountId as number} />
            </CustomTabPanel>
          </Box>
        </Grid>
      )}
    </>
  );
};
