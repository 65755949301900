import { Card, CardContent, Stack, Box, Typography } from "@mui/material";
import { warm, neutral } from "style/color";
import { moneyFormat } from "utils/money-format";

export const BalanceCard = (text: string, value: number) => {
  return (
    <Card sx={{ backgroundColor: warm[500], boxShadow: "none" }}>
      <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
        <Stack direction="row" spacing={2}>
          <Box>
            <Typography
              variant="subtitle3"
              sx={{
                color: neutral[700],
                display: "flex",
                alignItems: "center",
              }}
              gutterBottom
            >
              <Box sx={{ mr: 3 }}> {text}</Box>
            </Typography>
            <Typography variant="subtitle1" component={"div"}>
              ${moneyFormat(value)}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
