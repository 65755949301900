import { Edit } from "@refinedev/mui";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps } from "@refinedev/core";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  INVESTMENT_RISK_TYPE_ENUM,
  INVESTMENT_FORMAT_ENUM,
  IInvestment,
} from "interfaces/investment";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";

export const InvestmentUpdate: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const {
    refineCore: { formLoading, onFinish, queryResult },
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IInvestment>({
    refineCoreProps: {
      resource: `investments`,
      id: id,
      redirect: "list",
    },
  });
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const date = yesterday.toISOString().substring(0, 10);
  const [updateReferenceDate, setUpdateReferenceDate] = useState(false);
  const [referenceDate, setRefDate] = useState(date);
  return (
    <Edit
      recordItemId={id}
      title={
        <Typography variant="body1" fontWeight="bold">
          Update Investment
        </Typography>
      }
      isLoading={formLoading}
      saveButtonProps={{
        onClick: (e: React.BaseSyntheticEvent) => {
          const marketPrice = parseFloat(
            getValues("marketPrice").toString().replace(",", "")
          );
          setValue("marketPrice", marketPrice);

          if (updateReferenceDate) {
            const dailyChange = parseFloat(
              getValues("dailyChange").toString().replace(",", "")
            );
            setValue("dailyChange", dailyChange);
          }

          setValue("referenceDate", referenceDate);
          handleSubmit(onFinish, () => false)(e);
        },
      }}
    >
      {queryResult?.status === "loading" ||
      !queryResult?.isFetchedAfterMount ||
      queryResult?.fetchStatus === "fetching" ||
      formLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Tikcer"
                name="ticker"
                defaultValue={id}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                {...register("name", {
                  validate: (value, formValues) => {
                    if (!formValues.isListed && !value) {
                      return "name is required in case of unlisted investments";
                    }
                  },
                })}
                error={!!(errors as any)?.name}
                helperText={(errors as any)?.name?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Name"
                name="name"
                defaultValue={queryResult?.data?.data.name}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                {...register("description", {
                  validate: (value, formValues) => {
                    if (!formValues.isListed && !value) {
                      return "description is required in case of unlisted investments";
                    }
                  },
                })}
                error={!!(errors as any)?.description}
                helperText={(errors as any)?.description?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="textarea"
                rows={7}
                multiline
                label="Description"
                defaultValue={queryResult?.data?.data.description}
                name="description"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                {...register("riskType", {
                  required: "This riskType is required",
                })}
                error={!!(errors as any)?.riskType}
                helperText={(errors as any)?.riskType?.message}
                select
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={queryResult?.data?.data.riskType.toUpperCase()}
                label="Risk Type"
                name="riskType"
              >
                {[
                  {
                    value: INVESTMENT_RISK_TYPE_ENUM.EQUITY,
                    label: "Equity",
                  },
                  {
                    value: INVESTMENT_RISK_TYPE_ENUM.FIXED_INCOME,
                    label: "Fixed income",
                  },
                  {
                    value: INVESTMENT_RISK_TYPE_ENUM.OTHER,
                    label: "Other",
                  },
                ].map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                {...register("format", {
                  required: "This format is required",
                })}
                error={!!(errors as any)?.format}
                helperText={(errors as any)?.format?.message}
                select
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={queryResult.data?.data.format.toUpperCase()}
                label="Format"
                name="format"
              >
                {[
                  {
                    value: INVESTMENT_FORMAT_ENUM.SHARES,
                    label: "Shares",
                  },
                  {
                    value: INVESTMENT_FORMAT_ENUM.ETF,
                    label: "ETF- Exchange Traded Funds",
                  },
                  {
                    value: INVESTMENT_FORMAT_ENUM.MUTUAL_FUNDS,
                    label: "Mutual Funds",
                  },
                  {
                    value: INVESTMENT_FORMAT_ENUM.BOND,
                    label: "Bond",
                  },
                ].map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                defaultValue={queryResult?.data?.data.marketPrice || ""}
                error={!!(errors as any)?.marketPrice}
                helperText={(errors as any)?.marketPrice?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  if (queryResult?.data) {
                    const dailyChange =
                      ((parseFloat(event.target.value) -
                        queryResult?.data?.data.marketPrice) /
                        queryResult?.data?.data.marketPrice) *
                      100;
                    setValue("dailyChange", dailyChange.toFixed(4));
                    setValue("referenceDate", date);
                    setUpdateReferenceDate(true);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputComponent: NumericFormatCustom as any,
                }}
                label="Market Price"
                name="marketPrice"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                defaultValue={new Date(
                  queryResult?.data?.data?.priceLastFetchedAt as Date
                )
                  .toISOString()
                  .substring(0, 10)}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Last fetched Date"
              />
            </Grid>
            <Grid item xs={12}>
              <ToggleButtonGroup
                fullWidth
                size="medium"
                exclusive
                value={updateReferenceDate ? "OLD" : "LAST"}
                color="primary"
                sx={{ py: 1 }}
              >
                <ToggleButton
                  fullWidth
                  value={"LAST"}
                  aria-label="left aligned"
                  name="reinvest"
                  onClick={() => {
                    setValue("referenceDate", date);
                    setUpdateReferenceDate(false);
                  }}
                >
                  Update the last close market price
                </ToggleButton>
                <ToggleButton
                  fullWidth
                  value={"OLD"}
                  aria-label="centered"
                  name="reinvest"
                  onClick={() => {
                    setUpdateReferenceDate(true);
                  }}
                >
                  Update history of an old close price
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} md={updateReferenceDate ? 6 : 4}>
              <TextField
                {...register("marketPrice", {
                  validate: (value, formValues) => {
                    if (!formValues.isListed && !value) {
                      return "MarketPrice is required in case of unlisted investments";
                    }
                  },
                })}
                defaultValue={queryResult?.data?.data.marketPrice || ""}
                error={!!(errors as any)?.marketPrice}
                helperText={(errors as any)?.marketPrice?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  if (queryResult?.data) {
                    const dailyChange =
                      ((parseFloat(event.target.value) -
                        queryResult?.data?.data.marketPrice) /
                        queryResult?.data?.data.marketPrice) *
                      100;
                    setValue("marketPrice", event.target.value);
                    setValue("dailyChange", dailyChange.toFixed(4));
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputComponent: NumericFormatCustom as any,
                }}
                label="New Market Price"
                name="marketPrice"
              />
            </Grid>
            {!updateReferenceDate && (
              <Grid item xs={12} md={4}>
                <TextField
                  id="dailyChange"
                  {...register("dailyChange")}
                  disabled
                  value={getValues("dailyChange") || "0"}
                  error={!!(errors as any)?.dailyChange}
                  helperText={(errors as any)?.dailyChange?.message}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                    inputComponent: NumericFormatCustom as any,
                  }}
                  label="Daily Change"
                  name="dailyChange"
                />
              </Grid>
            )}
            <Grid item xs={12} md={updateReferenceDate ? 6 : 4}>
              <TextField
                id="referenceDate"
                {...register("referenceDate", {
                  validate: (value) => {
                    if (!value && updateReferenceDate) {
                      return "Reference date is required in case of unlisted investments";
                    }
                  },
                })}
                onChange={(e) => setRefDate(e.target.value)}
                defaultValue={referenceDate}
                disabled={!updateReferenceDate}
                error={!!(errors as any)?.referenceDate}
                helperText={(errors as any)?.referenceDate?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Reference Date"
                name="referenceDate"
              />
            </Grid>

            <Grid container xs={12} paddingLeft={2}>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  label="Is Listed"
                  control={
                    <Checkbox
                      {...register("isListed")}
                      name="isListed"
                      defaultChecked={queryResult?.data?.data.isListed}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  label="Is Active"
                  control={
                    <Checkbox
                      {...register("isActive")}
                      name="isActive"
                      defaultChecked={queryResult?.data?.data.isActive}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  label="Is Liquid"
                  control={
                    <Checkbox
                      {...register("isLiquid")}
                      name="isLiquid"
                      defaultChecked={queryResult?.data?.data.isLiquid}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  label="Is Available For Purchase"
                  control={
                    <Checkbox
                      {...register("isAFP")}
                      name="isAFP"
                      defaultChecked={queryResult?.data?.data.isAFP}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Edit>
  );
};
