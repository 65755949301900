import React, { useEffect, useState } from "react";
import * as yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useCustom, useUpdate } from "@refinedev/core";
import { useNavigate, useParams } from "react-router-dom";
import { EditButton, SaveButton, Show } from "@refinedev/mui";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { UserFundRequest } from "providers/rest-data-provider/utils/interfaces";
import { useFormik } from "formik";
import { DecideRequestModal } from "components/modal/user-request-modal";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

const validationSchema = yup.object({
  firstName: yup.string().required("firstName is required"),
  lastName: yup.string().required("lastName is required"),
  city: yup.string().required("city is required"),
  state: yup.string().required("state is required"),
  addressLine1: yup.string().required("addressLine1 is required"),
  zip: yup.number().required("zip is a required number"),
  phoneNumber: yup.string().required("phoneNumber is required"),
});

export const UserRequestDetails: React.FC = () => {
  const { sub } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [decision, setDecision] = useState<{
    sub: string;
    value: "PENDING" | "ACCEPTED" | "REJECTED";
  }>({ sub: "", value: "PENDING" });
  const [updateForm, setUpdateForm] = useState<boolean>(false);
  const { data, isLoading } = useCustom({
    url: `users/sub`,
    method: "get",
    config: {
      query: {
        sub: sub,
      },
    },
  });
  const [dateOfBirth, setDateOfBirth] = useState(null);
  useEffect(() => {
    if (data && data?.data.dateOfBirth) {
      const date = dayjs(data?.data?.dateOfBirth);
      setDateOfBirth(date as any);
    }
  }, [data]);
  const getDate = (newValue: any) => {
    setDateOfBirth(new Date(newValue).toLocaleDateString("en-us") as any);
  };
  const { mutate } = useUpdate();
  const formik = useFormik({
    initialValues: {
      userId: data?.data.id ?? "",
      sub: data?.data.sub ?? "",
      title: data?.data?.title ?? "",
      suffix: data?.data.suffix ?? "",
      middleName: data?.data.middleName ?? "",
      email: data?.data.email ?? "",
      firstName: data?.data?.firstName ?? "",
      lastName: data?.data.lastName ?? "",
      city: data?.data.city ?? "",
      state: data?.data.state ?? "",
      addressLine1: data?.data.addressLine1 ?? "",
      addressLine2: data?.data.addressLine2 ?? "",
      zip: data?.data.zip ?? "",
      phoneNumber: data?.data.phoneNumber ?? "",
      phoneNumberIsMobile: data?.data.phoneNumberIsMobile ?? false,
      reference: data?.data.reference ?? "",
      alternativePhoneNumberIsMobile:
        data?.data.alternativePhoneNumberIsMobile ?? false,
      alternativePhoneNumber: data?.data.alternativePhoneNumber ?? "",
      alternativeEmail: data?.data.alternativeEmail ?? "",
      status: data?.data.status ?? "",
      createdAt: data?.data.createdAt ?? "",
      fundAccount: {
        fundAccountName:
          data?.data?.fundAccounts[0]?.fund.fundAccountName ?? "",
        type: data?.data?.fundAccounts[0]?.fund.entityInformation
          ? true
          : false,
        fundAccountId: data?.data?.fundAccounts[0]?.fund.fundAccountId ?? "",
      },
      entityInformation: {
        entityName:
          data?.data?.fundAccounts[0]?.fund.entityInformation?.entityName ?? "",
        entityZip:
          data?.data?.fundAccounts[0]?.fund.entityInformation?.zip ?? "",
        entityCity:
          data?.data?.fundAccounts[0]?.fund.entityInformation?.city ?? "",
        entityState:
          data?.data?.fundAccounts[0]?.fund.entityInformation?.state ?? "",
        entityFirstAddressLine:
          data?.data?.fundAccounts[0]?.fund.entityInformation
            ?.firstAddressLine ?? "",
        entityType:
          data?.data?.fundAccounts[0]?.fund.entityInformation?.type ?? "",
        entitySecondAddressLine:
          data?.data?.fundAccounts[0]?.fund.entityInformation
            ?.secondAddressLine ?? "",
        entityRole:
          data?.data?.fundAccounts[0]?.fund.entityInformation?.role ?? "",
      },
    } as UserFundRequest,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      mutate({
        resource: "register/userWithFund",
        id: "",
        values: {
          fundAccountId: data?.data?.fundAccounts[0]?.fund.fundAccountId,
          sub: data?.data.sub,
          user: {
            title: values.title,
            suffix: values.suffix,
            middleName: values.middleName,
            email: values.email,
            alternativeEmail: values.alternativeEmail,
            firstName: values?.firstName,
            lastName: values.lastName,
            city: values.city,
            state: values.state,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            dateOfBirth: dateOfBirth,
            zip: values.zip,
            reference: values.reference,
            phoneNumber: values.phoneNumber,
            phoneNumberIsMobile: values.phoneNumberIsMobile,
            alternativePhoneNumberIsMobile:
              values.alternativePhoneNumberIsMobile,
            alternativePhoneNumber: values.alternativePhoneNumber,
          },
          account: {
            fundAccountName: values.fundAccount.fundAccountName,
            entityInformation: {
              entityName: values.entityInformation?.entityName,
              zip: values.entityInformation?.entityZip,
              city: values.entityInformation?.entityCity,
              state: values.entityInformation?.entityState,
              firstAddressLine:
                values.entityInformation?.entityFirstAddressLine,
              secondAddressLine:
                values.entityInformation?.entitySecondAddressLine,
              type: values.entityInformation?.entityType,
              role: values.entityInformation?.entityRole,
            },
          },
        },
      });
      setUpdateForm(false);
    },
  });
  return isLoading ? (
    <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Show
          isLoading={isLoading}
          title="User request details"
          goBack={
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
          }
          headerButtons={
            <>
              {!updateForm ? (
                <EditButton
                  onClick={() => {
                    setUpdateForm(true);
                  }}
                />
              ) : (
                <SaveButton disabled={!formik.isValid} type="submit" />
              )}
            </>
          }
        >
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column" }}
            autoComplete="off"
          >
            {!updateForm ? (
              <TextField
                value={`${formik.values?.title ? formik.values?.title : ""} ${
                  formik.values?.firstName ? formik.values?.firstName : ""
                } ${
                  formik.values?.middleName ? formik.values?.middleName : ""
                }${formik.values?.lastName ? formik.values?.lastName : ""} ${
                  formik.values?.suffix ? formik.values?.suffix : ""
                }`}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Name "
                name="title"
                disabled={!updateForm}
              />
            ) : (
              <div>
                <Grid container>
                  <Grid xs={12} sm={1} md={1} paddingRight={{ md: 2 }}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Title</InputLabel>
                      <Select
                        id="demo-simple-select"
                        value={formik.values?.title && formik.values?.title}
                        label="title"
                        onChange={(e) =>
                          formik.setFieldValue("title", e.target.value)
                        }
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value={"Mr"}>Mr</MenuItem>
                        <MenuItem value={"Miss"}>Ms</MenuItem>
                        <MenuItem value={"Miss"}>Miss</MenuItem>
                        <MenuItem value={"Mrs"}>Mrs</MenuItem>
                        <MenuItem value={"Mx"}>Mx</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={12} md={3} paddingRight={{ md: 2 }}>
                    <TextField
                      defaultValue={formik.values?.firstName}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="First Name "
                      name="firstName"
                      disabled={!updateForm}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                    />
                  </Grid>
                  <Grid xs={12} sm={12} md={3} paddingRight={{ md: 2 }}>
                    <TextField
                      defaultValue={
                        formik.values?.middleName && formik.values?.middleName
                      }
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Middle Name "
                      name="middleName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid xs={12} sm={12} md={3} paddingRight={{ md: 2 }}>
                    <TextField
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Last Name "
                      name="lastName"
                      disabled={!updateForm}
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                    />
                  </Grid>
                  <Grid xs={12} sm={12} md={2} flexGrow={2}>
                    <TextField
                      defaultValue={
                        formik.values?.suffix && formik.values?.suffix
                      }
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Suffix"
                      name="suffix"
                      disabled={!updateForm}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                </Grid>
              </div>
            )}

            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              margin="normal"
              InputLabelProps={{ shrink: true }}
              defaultValue={formik.values.email}
              value={formik.values.email}
              type="text"
              disabled
            />
            {dateOfBirth && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl fullWidth>
                  <DatePicker
                    slotProps={{
                      textField: {
                        id: "datePicker",
                        name: "dateOfBirth",
                        required: true,
                      },
                    }}
                    label="Date of birth"
                    disableFuture={true}
                    format="MM/DD/YYYY"
                    name="dateOfBirth"
                    value={dateOfBirth}
                    disabled={!updateForm}
                    onChange={getDate}
                  />
                </FormControl>
              </LocalizationProvider>
            )}

            {!updateForm ? (
              <>
                <TextField
                  value={`${
                    formik.values?.addressLine1 &&
                    `${formik.values?.addressLine1}`
                  }${formik.values?.city && `, ${formik.values?.city}`}${
                    formik.values?.state && `, ${formik.values?.state}`
                  } ${formik.values?.zip && `, ${formik.values?.zip}`} `}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Address "
                  name="addressLine1"
                  disabled={!updateForm}
                />
                {formik.values?.addressLine2 && (
                  <TextField
                    value={formik.values?.addressLine2}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Second Address "
                    name="addressLine2"
                    disabled={!updateForm}
                  />
                )}
              </>
            ) : (
              <div>
                <Grid container>
                  <Grid xs={12} sm={12} md={3} paddingRight={{ md: 2 }}>
                    <TextField
                      value={formik.values.addressLine1}
                      margin="normal"
                      required
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Address line 1 "
                      name="addressLine1"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.addressLine1 &&
                        Boolean(formik.errors.addressLine1)
                      }
                    />
                  </Grid>
                  <Grid xs={12} sm={12} md={3} paddingRight={{ md: 2 }}>
                    <TextField
                      value={
                        formik.values?.addressLine2
                          ? formik.values?.addressLine2
                          : ""
                      }
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Address line 2"
                      name="addressLine2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid xs={12} sm={12} md={2} paddingRight={{ md: 2 }}>
                    <TextField
                      value={formik.values.city}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="City"
                      name="city"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                    />
                  </Grid>
                  <Grid xs={12} sm={12} md={2} paddingRight={{ md: 2 }}>
                    <TextField
                      value={formik.values.state}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="State"
                      name="state"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                    />
                  </Grid>
                  <Grid xs={12} sm={12} md={2} paddingRight={{ md: 2 }}>
                    <TextField
                      value={formik.values.zip}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Zip"
                      name="zip"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.zip && Boolean(formik.errors.zip)}
                      helperText={
                        formik.touched.zip &&
                        (formik.errors.zip?.toString() as string)
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            {!updateForm ? (
              <TextField
                value={`${formik.values?.phoneNumber}  ${
                  formik.values?.phoneNumberIsMobile
                    ? " is mobile number"
                    : "is not a mobile"
                }`}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Phone number"
                name="phoneNumber"
                disabled={!updateForm}
              />
            ) : (
              <div>
                <Grid container>
                  <Grid xs={12} sm={12} md={4} paddingRight={{ md: 2 }}>
                    <TextField
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Phone number"
                      name="phoneNumber"
                      disabled={!updateForm}
                      required
                      defaultValue={
                        formik.values?.phoneNumber && formik.values?.phoneNumber
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.phoneNumber &&
                        Boolean(formik.errors.phoneNumber)
                      }
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={2}
                    paddingRight={{ md: 2 }}
                    marginTop={{ md: 3 }}
                  >
                    <Radio
                      name="radio-buttons"
                      checked={
                        formik.values?.phoneNumberIsMobile ? true : false
                      }
                      onChange={(e) =>
                        formik.setFieldValue("phoneNumberIsMobile", true)
                      }
                    />
                    is Mobile
                  </Grid>

                  <Grid
                    xs={12}
                    sm={12}
                    md={3}
                    paddingRight={{ md: 2 }}
                    marginTop={{ md: 3 }}
                  >
                    <Radio
                      checked={
                        formik.values?.phoneNumberIsMobile ? false : true
                      }
                      name="radio-buttons"
                      onChange={(e) =>
                        formik.setFieldValue("phoneNumberIsMobile", false)
                      }
                    />
                    is not mobile
                  </Grid>
                </Grid>
              </div>
            )}

            {formik.values?.alternativePhoneNumber && !updateForm && (
              <TextField
                value={`${formik.values?.alternativePhoneNumber}  ${
                  formik.values?.alternativePhoneNumberIsMobile
                    ? " is mobile number"
                    : "is not a mobile"
                }`}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Phone number"
                name="alternativePhoneNumber"
                disabled={!updateForm}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.alternativePhoneNumber &&
                  Boolean(formik.errors.alternativePhoneNumber)
                }
              />
            )}
            {formik.values?.alternativePhoneNumber && updateForm && (
              <div>
                <Grid container>
                  <Grid xs={12} sm={12} md={4} paddingRight={{ md: 2 }}>
                    <TextField
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Alternative phone number"
                      name="alternativePhoneNumber"
                      disabled={!updateForm}
                      defaultValue={
                        formik.values?.alternativePhoneNumber &&
                        formik.values?.alternativePhoneNumber
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.alternativePhoneNumber &&
                        Boolean(formik.errors.alternativePhoneNumber)
                      }
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={2}
                    paddingRight={{ md: 2 }}
                    marginTop={{ md: 3 }}
                  >
                    <Radio
                      name="radio-buttons"
                      checked={
                        formik.values?.alternativePhoneNumberIsMobile
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        formik.setFieldValue(
                          "alternativePhoneNumberIsMobile",
                          true
                        )
                      }
                    />
                    is Mobile
                  </Grid>

                  <Grid
                    xs={12}
                    sm={12}
                    md={3}
                    paddingRight={{ md: 2 }}
                    marginTop={{ md: 3 }}
                  >
                    <Radio
                      checked={
                        formik.values?.alternativePhoneNumberIsMobile
                          ? false
                          : true
                      }
                      name="radio-buttons"
                      onChange={(e) =>
                        formik.setFieldValue(
                          "alternativePhoneNumberIsMobile",
                          false
                        )
                      }
                    />
                    is not mobile
                  </Grid>
                </Grid>
              </div>
            )}
            <TextField
              value={formik.values?.reference && formik.values?.reference}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Reference"
              name="reference"
              disabled={!updateForm}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <TextField
              fullWidth
              id="alternativeEmail"
              name="alternativeEmail"
              label="Alternate email"
              margin="normal"
              InputLabelProps={{ shrink: true }}
              defaultValue={formik.values.alternativeEmail}
              value={formik.values.alternativeEmail}
              type="text"
              disabled
            />
            {formik.values?.fundAccount && (
              <>
                <Typography variant="body1" fontWeight="bold">
                  Fund Details:
                </Typography>

                <TextField
                  value={
                    formik.values?.fundAccount?.fundAccountName &&
                    formik.values?.fundAccount?.fundAccountName
                  }
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Fund Name"
                  name="fundAccount.fundAccountName"
                  disabled={!updateForm}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <TextField
                  value={
                    formik.values.entityInformation?.entityName
                      ? "An entity"
                      : "Personal"
                  }
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Fund type"
                  name="fundAccount.type"
                  disabled
                />
              </>
            )}
            {formik.values.entityInformation?.entityName && (
              <>
                <Typography variant="body1" fontWeight="bold">
                  Fund entity information:
                </Typography>
                <TextField
                  value={
                    formik.values.entityInformation.entityName &&
                    formik.values.entityInformation.entityName
                  }
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Entity name "
                  name="entityInformation.entityName"
                  disabled={!updateForm}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <TextField
                  value={
                    formik.values.entityInformation.entityType &&
                    formik.values.entityInformation.entityType
                  }
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Entity type"
                  name="entityInformation.entityType"
                  disabled={!updateForm}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <TextField
                  value={
                    formik.values.entityInformation.entityFirstAddressLine &&
                    formik.values.entityInformation.entityFirstAddressLine
                  }
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Entity Address "
                  name="entityInformation.entityFirstAddressLine"
                  disabled={!updateForm}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <TextField
                  value={formik.values?.entityInformation.entityCity}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="City"
                  name="entityInformation.entityCity"
                  disabled={!updateForm}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <TextField
                  value={formik.values?.entityInformation.entityZip}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Zip"
                  name="entityInformation.entityZip"
                  disabled={!updateForm}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <TextField
                  value={formik.values.entityInformation?.entityState}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="State"
                  name="entityInformation.entityState"
                  disabled={!updateForm}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.values?.entityInformation.entitySecondAddressLine && (
                  <TextField
                    value={
                      formik.values?.entityInformation.entitySecondAddressLine
                    }
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Entity second Address "
                    name="entityInformation.entitySecondAddressLine"
                    disabled={!updateForm}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                )}
                <TextField
                  value={formik.values?.entityInformation.entityRole}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="User role in the entity"
                  name="entityInformation.entityRole"
                  disabled={!updateForm}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </>
            )}

            <TextField
              value={formik.values?.status}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Status"
              name="status"
              disabled
            />
            <TextField
              value={formik.values?.createdAt}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Request creation date"
              name="createdAt"
              disabled
            />
          </Box>{" "}
          {!updateForm && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",

                justifyContent: "flex-end",
              }}
            >
              <DecideRequestModal
                visible={open}
                close={setOpen}
                decision={decision}
              />

              <Button
                size="large"
                variant="contained"
                style={{ marginRight: "0.5rem" }}
                color="primary"
                onClick={() => {
                  setOpen(true);
                  setDecision({ sub: data?.data.sub, value: "ACCEPTED" });
                }}
              >
                Accept
              </Button>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setOpen(true);
                  setDecision({ sub: data?.data.sub, value: "REJECTED" });
                }}
              >
                Reject
              </Button>
            </Box>
          )}
        </Show>
      </form>
    </>
  );
};
