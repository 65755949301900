import { Button, Divider } from "@aws-amplify/ui-react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import { IInvestment } from "interfaces/investment";
import { moneyFormat, unitFormat } from "utils/money-format";
import NavigateNextIcon from '@mui/icons-material/NavigateNext'; interface InvestmentDetailsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  investment: IInvestment;
}
export const InvestmentDetailsModal: React.FC<InvestmentDetailsModalProps> = ({
  setOpen,
  open,
  investment,
}) => {
  const {
    name,
    ticker,
    dailyChange,
    marketPrice,
    riskType,
    format,
    isActive,
    isLiquid,
    isListed,
    isAFP,
    totalInvestments
  } = investment;
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{name} Details</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            Description {investment.description}
          </DialogContentText>
          <TextField
            disabled
            value={ticker}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Ticker"
          />
          <TextField
            disabled
            value={moneyFormat(marketPrice, 6)}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Market price"
          />
          <TextField
            disabled
            value={` ${dailyChange >= 0
              ? `${moneyFormat(dailyChange, 2)}`
              : moneyFormat(dailyChange, 2)
              }%`}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Daily change"
          />
          <TextField
            disabled
            value={riskType}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Risk Type"
          />

          <TextField
            disabled
            value={format}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Format"
          />
          <FormControlLabel
            disabled
            checked={isActive}
            control={<Checkbox value={isActive} />}
            label="Active"
          />
          <FormControlLabel
            disabled
            checked={isListed}
            control={<Checkbox value={isListed} />}
            label="Listed"
          />
          <FormControlLabel
            disabled
            checked={isLiquid}
            control={<Checkbox value={isLiquid} />}
            label="Liquid"
          />

          <FormControlLabel
            disabled
            checked={isAFP}
            control={<Checkbox value={isAFP} />}
            label="AFP"
          />
          {totalInvestments && (
            // because we my have fund investment rows with zero approved
            <>
              <List sx={{
                border: 'grey solid 1px', borderRadius: '8px'

              }}>
                <ListItem>
                  <ListItemText primary={`Neta has ${unitFormat(
                    totalInvestments.totalAmount
                  )} units distributed on `} />
                </ListItem>
                {
                  totalInvestments.funds.map(fund => {
                    return <ListItem>
                      <ListItemIcon>
                        <NavigateNextIcon />
                      </ListItemIcon>
                      <ListItemText primary={fund} />
                    </ListItem>
                  }
                  )
                }
              </List>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
