import { Alert, CircularProgress, Divider, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useUpdate } from "@refinedev/core";
import {
} from "interfaces/contribution-transaction";
import { IGrantRequest, IScheduledGrantRequest } from "interfaces/grant-requests";
import { useState } from "react";

interface ModalProps {
  visible: boolean;
  data: {
    grantRequest: IGrantRequest | undefined
    scheduledGrantRequest: IScheduledGrantRequest | undefined;
    value: "PENDING" | "PROCESSED";
  };
  close: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: () => void;
}
export const EvaluateScheduledGrantRequestModal: React.FC<ModalProps> = ({
  visible,
  close,
  data,
  refresh
}) => {

  const [pendingRequestError, setPendingRequestError] = useState("");
  const { mutate, isLoading } = useUpdate();
  return (
    <Dialog open={visible} onClose={() => close(false)}>
      <DialogTitle color="primary">

        Process this user grant request transaction
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Are you sure you want to{" "}
          Process this user
          grant request transaction?
        </Typography>
        {
          pendingRequestError !== "" && (
            <Alert sx={{
              mt: 1
            }} severity="error">{pendingRequestError}.</Alert>
          )
        }
      </DialogContent>
      <Divider />
      <DialogActions>
        <>
          <Button
            disabled={isLoading}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            variant="contained"
            onClick={() => {
              setPendingRequestError("")
              mutate(
                {
                  resource: "grant-requests",
                  values: {
                    status: data.value,
                  }
                  ,
                  id: `${data.grantRequest?.grantRequestId as any}/scheduledGrantRequests/${data.scheduledGrantRequest?.scheduledGrantRequestId as any}/status`,
                },
                {
                  onSuccess: () => {
                    refresh()
                    close(false);
                  },
                  onError: (error) => {
                    setPendingRequestError(error.message)
                  }
                }
              );

            }}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              close(false);
            }}
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
