import React, { useState } from "react";
import {
  CircularProgress,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useCustom } from "@refinedev/core";
import { List } from "@refinedev/mui";
// import { ICategory } from "interfaces/category";
// import PaginatedEntityList from "interfaces/PaginatedEntityList";
// import ClearIcon from "@mui/icons-material/Clear";
// import { InView } from "react-intersection-observer";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";

export const CategoryList: React.FC = () => {
  // const [categoriesData, setCategoriesData] = useState<ICategory[]>([]);
  const navigate = useNavigate();
  // const [offset, setOffset] = useState(0);
  // const [limit] = useState(30);
  const [keyword, setKeyword] = useState<string>("");

  const {
    data: categories,
    isSuccess: isCategoriesSuccess,
    isFetching: isCategoriesLoading,
  } = useCustom({
    url: `categories`,
    method: "get",
    config: {
      query: {
        keyword: keyword,
        limit: 30,
        offset: 0,
      },
    },
  });

  // useEffect(() => {
  //   if (categories) {
  //     if (
  //       (categories.data as PaginatedEntityList<ICategory>)?.pagination
  //         ?.offset === 0
  //     ) {
  //       setCategoriesData(
  //         (categories.data as PaginatedEntityList<ICategory>).data
  //       );
  //       const length = (categories.data as PaginatedEntityList<ICategory>).data
  //         .length;
  //       setOffset(length);
  //     } else {
  //       setCategoriesData([
  //         ...categoriesData,
  //         ...(categories.data as PaginatedEntityList<ICategory>).data,
  //       ]);
  //       setOffset(categoriesData.length);
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [categories]);

  // const fetchData = (inView: boolean) => {
  //   if (inView && categories?.data?.pagination.nextOffset) {
  //     // setOffset(categories?.data?.pagination.nextOffset);
  //   }
  // };

  // const clear = () => {
  //   setCategoriesData([]);
  //   setOffset(0);
  // };
  // const handleWordChange = (event: any) => {
  //   const { value } = event.target;
  //   if (event.key === "Enter") {
  //     clear();
  //     setKeyword(value);
  //   }
  // };
  return (
    <List
      title="Categories List"
      wrapperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          padding: 0,
        },
      }}
    >
      <Grid item xs={12} md={4} mb={1}>
        <FormControl variant="outlined" fullWidth>
          <FilledInput
            className="search"
            placeholder="Type keyword or name"
            size="small"
            id="keyword"
            defaultValue={keyword}
            name="keyword"
            disabled
            onChange={(event) => event?.target.value === "" && setKeyword("")}
            // onKeyDown={(event) => handleWordChange(event)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      {/* {keyword && (
        <Grid margin={{ md: 1 }} my={1}>
          <FormControl size="small">
            <OutlinedInput
              value={keyword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setKeyword("");
                      clear();
                    }}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
            ></OutlinedInput>
          </FormControl>
        </Grid>
      )} */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell color="primary">ID</TableCell>
              <TableCell color="primary" align="center">
                Name
              </TableCell>
              <TableCell color="primary" align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isCategoriesSuccess && (
              <>
                {categories &&
                  categories.data.data.length > 0 &&
                  categories.data.data.map((row: any) => (
                    <TableRow
                      key={row.categoryId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{row.categoryId}</TableCell>
                      <TableCell align="center">{row.categoryName}</TableCell>
                      <TableCell align="right">
                        <Grid container justifyContent={"flex-end"}>
                          <IconButton
                            onClick={() => {
                              navigate(`/categories/${row.categoryId}/edit`);
                            }}
                            edge="end"
                          >
                            <SettingsIcon />
                          </IconButton>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
              </>
            )}

            {isCategoriesLoading && (
              <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
                {" "}
                <CircularProgress color="primary" />
              </Grid>
            )}
            {/* {isCategoriesLoading ? (
              <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
                {" "}
                <CircularProgress color="primary" />
              </Grid>
            ) : categories?.data?.pagination.hasNext ? (
              <InView
                as="div"
                onChange={(inView) => fetchData(inView)}
              ></InView>
            ) : null} */}
            {!isCategoriesLoading && categories?.data.data.length === 0 && (
              <Typography variant="subtitle3">No category found.</Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </List>
  );
};
