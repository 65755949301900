import { IResourceComponentsProps, useCustom, useUpdate } from "@refinedev/core";
import {
  Box,
  Button,
  CircularProgress,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  INVESTMENT_FORMAT_ENUM,
  INVESTMENT_RISK_TYPE_ENUM,
  IInvestment,
} from "interfaces/investment";
import { InvestmentCard } from "components/card/InvestmentCard";
import {
  SelectMultipleCheckboxDropdown,
  Option,
} from "components/inputs/SelectMultipleCheckboxDropdown";
import PaginatedEntityList from "interfaces/PaginatedEntityList";
import { InView } from "react-intersection-observer";
import { List } from "@refinedev/mui";
import { InvestmentBatchUpdateModal } from "components/modal/investment-batch-update-modal";
import { useNavigate } from "react-router-dom";

const risksOptions: Option[] = [
  {
    key: 1,
    label: "Equity",
    value: INVESTMENT_RISK_TYPE_ENUM.EQUITY,
  },
  {
    key: 2,
    label: "Fixed Income",
    value: INVESTMENT_RISK_TYPE_ENUM.FIXED_INCOME,
  },
  {
    key: 3,
    label: "Other",
    value: INVESTMENT_RISK_TYPE_ENUM.OTHER,
  },
];

const formatOptions: Option[] = [
  {
    key: 1,
    label: "Shares",
    value: INVESTMENT_FORMAT_ENUM.SHARES,
  },
  {
    key: 2,
    label: "ETF- Exchange Traded Funds",
    value: INVESTMENT_FORMAT_ENUM.ETF,
  },
  {
    key: 3,
    label: "Mutual Funds",
    value: INVESTMENT_FORMAT_ENUM.MUTUAL_FUNDS,
  },
  {
    key: 4,
    label: "Bond",
    value: INVESTMENT_FORMAT_ENUM.BOND,
  },
];

export const InvestmentsList: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [risks, setRisks] = useState<Option[]>([]);
  const [formats, setFormats] = useState<Option[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [offset, setOffset] = useState(0);
  const [investmentData, setInvestmentData] = useState<IInvestment[]>([]);
  const [limit] = useState(5);
  const {
    data: investments,
    isSuccess: isGetInvestmentSuccess,
    isFetching: isGetInvestmentsLoading,
    refetch,
  } = useCustom({
    url: `investments`,
    method: "get",
    config: {
      query: {
        limit: limit,
        offset: offset,
        searchTerm: keyword,
      },
      filters: [
        {
          field: "filter[riskTypes]",
          operator: "eq",
          value: [
            ...(risks.map((risk) => risk.value) as [INVESTMENT_RISK_TYPE_ENUM]),
            "",
          ],
        },
        {
          field: "filter[formats]",
          operator: "eq",
          value: [
            ...(formats.map((format) => format.value) as [
              INVESTMENT_FORMAT_ENUM
            ]),
            "",
          ],
        },
      ],
    },
  });
  const { mutate, isLoading } = useUpdate();
  const closeModal = () => {
    setOpen(false);
    refetch();
  };
  useEffect(() => {
    if (investments) {
      if (
        (investments.data as PaginatedEntityList<IInvestment>)?.pagination
          ?.offset === 0
      ) {
        setInvestmentData(
          (investments.data as PaginatedEntityList<IInvestment>).data
        );
      } else {
        setInvestmentData([
          ...investmentData,
          ...(investments.data as PaginatedEntityList<IInvestment>).data,
        ]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investments]);

  const fetchData = (inView: boolean) => {
    if (inView && investments?.data?.pagination.nextOffset)
      setOffset(investments?.data?.pagination.nextOffset);
  };

  const clear = () => {
    setInvestmentData([]);
    setOffset(0);
  };
  const removeOption = (
    item: Option,
    setValue: React.Dispatch<React.SetStateAction<Option[]>>,
    array: Option[]
  ) => {
    let options: Option[] = [];
    options = array.filter((selected) => selected.key !== item.key);
    setValue(options);
  };

  const handleWordChange = (event: any) => {
    const { value } = event.target;
    if (event.key === "Enter") {
      clear();
      setKeyword(value);
    }
  };
  const fetch = () => {
    mutate(
      {
        resource: "update-ticker-prices",
        values: {},
        id: "",
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };
  return (
    <>
      <InvestmentBatchUpdateModal open={open} closeModal={closeModal} />
      <List
        title="Investment List"
        headerButtons={({ defaultButtons }) => (
          <>
            {defaultButtons}
            <Button
              color="primary"
              onClick={() => navigate('/investments/history')}
              variant="contained"
            >
              Edit
            </Button>
            <Button
              color="primary"
              onClick={() => fetch()}
              variant="contained"
              disabled={isLoading}
            >
              Fetch new prices
            </Button>
          </>
        )}
        wrapperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,
          },
        }}
      >
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={12} md={4}>
            <SelectMultipleCheckboxDropdown
              setSelected={setRisks}
              options={risksOptions}
              selected={risks}
              title={"All Risk Types"}
              clear={clear}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl size="small" fullWidth>
              <SelectMultipleCheckboxDropdown
                setSelected={setFormats}
                options={formatOptions}
                selected={formats}
                title={"All Formats"}
                clear={clear}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" fullWidth>
              <FilledInput
                className="search"
                placeholder="Type keyword or name"
                size="small"
                id="keyword"
                defaultValue={keyword}
                name="keyword"
                onChange={(event) =>
                  event?.target.value === "" && setKeyword("")
                }
                onKeyDown={(event) => handleWordChange(event)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          sx={{ my: 2 }}
          container
          justifyContent={{ xs: "start", md: "flex-wrap" }}
          alignItems="center"
          columns={16}
          spacing={{ xs: 1, md: 0 }}
          rowSpacing={2}
        >
          {((risks && risks.length > 0) ||
            (formats && formats.length) ||
            keyword) && (
              <Typography
                sx={{ color: "neutral.main" }}
                variant="subtitle3"
                component={"span"}
              >
                Showing results for
              </Typography>
            )}
          {risks &&
            risks.length > 0 &&
            risks.map((risk) => (
              <Grid margin={{ md: 1 }}>
                <FormControl size="small">
                  <OutlinedInput
                    value={risk.label}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            removeOption(risk, setRisks, risks);
                            clear();
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  ></OutlinedInput>
                </FormControl>
              </Grid>
            ))}
          {formats &&
            formats.length > 0 &&
            formats.map((format) => (
              <Grid margin={{ md: 1 }}>
                <FormControl size="small">
                  <OutlinedInput
                    value={format.label}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            removeOption(format, setFormats, formats);
                            clear();
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  ></OutlinedInput>
                </FormControl>
              </Grid>
            ))}
          {keyword && (
            <Grid margin={{ md: 1 }}>
              <FormControl size="small">
                <OutlinedInput
                  value={keyword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setKeyword("");
                          clear();
                        }}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                ></OutlinedInput>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Box sx={{ marginY: 4 }}>
          {isGetInvestmentSuccess && (
            <>
              <Grid container rowSpacing={2}>
                {investmentData &&
                  investmentData.length > 0 &&
                  investmentData.map((investment, index) => (
                    <Grid key={`investment-card-key-${index}`} item xs={12}>
                      <InvestmentCard {...investment} />
                    </Grid>
                  ))}
              </Grid>
            </>
          )}

          {isGetInvestmentsLoading ? (
            <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          ) : investments?.data?.pagination.hasNext ? (
            <InView as="div" onChange={(inView) => fetchData(inView)}></InView>
          ) : null}
          {!isGetInvestmentsLoading && investmentData.length === 0 && (
            <Typography variant="subtitle3">No investment found.</Typography>
          )}
        </Box>
      </List>
    </>
  );
};
