import { Show, useDataGrid } from "@refinedev/mui";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Link,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { IResourceComponentsProps, useCustom, useShow } from "@refinedev/core";
import { useNavigate, useParams } from "react-router-dom";
import {
  GRANT_PURPOSE_ENUM,
  GRANT_REQUEST_STATUS_ENUM,
  GRANT_REQUEST_TRANSACTION_TYPE_ENUM,
  GRANT_REQUEST_TYPE_ENUM,
  IGrantRequest,
  IScheduledGrantRequest,
  InvestmentInfo,
  OnTimeSchedule,
  RecurrentSchedule,
  SCHEDULED_GRANT_REQUEST_STATUS_ENUM,
} from "interfaces/grant-requests";
import { primary } from "style/color";
import { moneyFormat } from "utils/money-format";
import { shortFormat } from "utils/date-format";
import { EvaluateGrantRequestModal } from "components/modal/evaluate-grant-request";
import { useState } from "react";
import { IInvestmentRequest } from "interfaces/investment-requests";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import { EvaluateScheduledGrantRequestModal } from "components/modal/evaluate-scheduled-grant-request";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

export const GrantRequestDetails: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const { dataGridProps } = useDataGrid<IScheduledGrantRequest>({
    queryOptions: { enabled: false },
  });

  const { queryResult } = useShow<IGrantRequest>({
    resource: "grant-requests",
    id: id,
  });
  const { data, refetch } = queryResult;
  const record = data?.data;
  const [open, setOpen] = useState(false);
  const [openProcess, setOpenProcess] = useState(false);
  const [decision, setDecision] = useState<{
    grantRequest: IGrantRequest | undefined;
    value: "PENDING" | "APPROVED" | "REJECTED" | "PROCESSED";
  }>({ grantRequest: undefined, value: "PENDING" });
  const [processDecision, setProcessDecision] = useState<{
    grantRequest: IGrantRequest | undefined;
    scheduledGrantRequest: IScheduledGrantRequest | undefined;
    value: "PENDING" | "PROCESSED";
  }>({
    grantRequest: undefined,
    scheduledGrantRequest: undefined,
    value: "PENDING",
  });
  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "scheduledGrantRequestId",
        flex: 1,
        headerName: "ID",
        minWidth: 80,
        renderCell: function render({ value }: any) {
          return value.scheduledGrantRequestId;
        },
      },

      {
        field: "status",
        flex: 1,
        headerName: "status",
        minWidth: 100,
      },
      {
        field: "executionDate",
        flex: 1,
        headerName: "executionDate",
        minWidth: 100,
        renderCell: function render({ value }: any) {
          return shortFormat(value);
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return row.status === SCHEDULED_GRANT_REQUEST_STATUS_ENUM.APPROVED ? (
            <Button
              size="medium"
              variant="contained"
              style={{ marginRight: "0.5rem" }}
              color="primary"
              onClick={() => {
                setOpenProcess(true);
                setProcessDecision({
                  grantRequest: record,
                  scheduledGrantRequest: row,
                  value: "PROCESSED",
                });
              }}
            >
              Process
            </Button>
          ) : row.status === SCHEDULED_GRANT_REQUEST_STATUS_ENUM.PROCESSED ? (
            row.receipt && (
              <Link
                href={row.receipt?.url}
                target="__blank"
                underline="none"
                color="inherit"
              >
                <ArrowCircleDownIcon />
              </Link>
            )
          ) : null;
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  window.history.replaceState(null, "");
  const { data: investmentRequestData, isLoading } = useCustom({
    url: `investments-requests`,
    method: "get",
    config: {
      query: {},
      filters: [
        {
          field: "filter[grantRequestId]",
          operator: "eq",
          value: record?.grantRequestId,
        },
      ],
    },
    queryOptions: {
      enabled: !!record?.grantRequestId,
    },
  });
  const navigate = useNavigate();
  return (
    <Show
      isLoading={queryResult.isLoading}
      title={
        <Typography variant="h5" color={primary}>
          Grant Request Details
        </Typography>
      }
    >
      {!queryResult.isLoading && (
        <>
          {" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",

              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            {" "}
            {record?.type === GRANT_REQUEST_TYPE_ENUM.ONETIME &&
              record?.status === GRANT_REQUEST_STATUS_ENUM.PROCESSED &&
              record?.scheduledGrantRequests &&
              record?.scheduledGrantRequests[0]?.status ===
                SCHEDULED_GRANT_REQUEST_STATUS_ENUM.PROCESSED &&
              record.scheduledGrantRequests[0].receipt && (
                <Link
                  href={record?.scheduledGrantRequests[0]?.receipt?.url}
                  target="__blank"
                  underline="none"
                  color="inherit"
                >
                  {" "}
                  <Button
                    fullWidth
                    startIcon={<ArrowCircleDownIcon />}
                    variant="contained"
                  >
                    Download
                  </Button>{" "}
                </Link>
              )}
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                value={`${record?.user?.title ? record?.user?.title : ""} ${
                  record?.user?.firstName ? record?.user?.firstName : ""
                } ${record?.user?.middleName ? record?.user?.middleName : ""}${
                  record?.user?.lastName ? record.user?.lastName : ""
                } ${record?.user?.suffix ? record?.user?.suffix : ""}`}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="User "
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={record?.fundAccount?.fundAccountName}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Fund Name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={record?.charity.ein}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Charity Ein"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={record?.charity.name}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Charity Name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={record?.type}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Grant Type"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={record?.status}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Request status"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="bodyCopyBold" color={primary}>
                Requested{" "}
                <Typography variant="bodyCopyRegular" color={primary[500]}>
                  {" "}
                  {record?.transactionType}{" "}
                </Typography>
                information
              </Typography>
            </Grid>

            {record?.transactionType &&
              record.transactionType ===
                GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT && (
                <>
                  <Grid item xs={12} md={6}>
                    <TextField
                      disabled
                      value={(record.information as InvestmentInfo).ticker}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Ticker"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      disabled
                      value={
                        (record.information as InvestmentInfo).interestedIn
                      }
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Interested In "
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      disabled
                      value={moneyFormat(
                        (record.information as InvestmentInfo).units,
                        4
                      )}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Units"
                    />{" "}
                  </Grid>
                </>
              )}
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={`$${moneyFormat(
                  record?.information.requestedAmount || 0
                )}`}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Requested Amount"
              />
            </Grid>

            {record?.status === GRANT_REQUEST_STATUS_ENUM.PROCESSED && (
              <Grid item xs={12} md={6}>
                <TextField
                  disabled
                  value={`$${moneyFormat(record?.information.appliedAmount)}`}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Applied Amount"
                />{" "}
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={`$${moneyFormat(
                  record?.information.appliedAmount || 0
                )}`}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Applied Amount"
              />{" "}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={record?.toInform}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Person to inform"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={shortFormat(record?.createdAt)}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Creation date"
              />
            </Grid>
            {record?.status === GRANT_REQUEST_STATUS_ENUM.APPROVED && (
              <Grid item xs={12} md={6}>
                <TextField
                  disabled
                  value={shortFormat(record?.updatedAt)}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Approval date"
                />
              </Grid>
            )}
            {record?.status === GRANT_REQUEST_STATUS_ENUM.PROCESSED && (
              <Grid item xs={12} md={6}>
                <TextField
                  disabled
                  value={shortFormat(record?.updatedAt)}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Process date"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="bodyCopyBold" color={primary}>
                Request type is
                <Typography variant="bodyCopyRegular" color={primary[500]}>
                  {" "}
                  {record?.type}{" "}
                </Typography>
              </Typography>
            </Grid>
            {record?.type === GRANT_REQUEST_TYPE_ENUM.RECURRENT &&
              (record?.scheduleInformation as RecurrentSchedule).startingOn && (
                <Grid item xs={12}>
                  <Typography variant="bodyCopyRegular" color={primary[500]}>
                    Start date{" "}
                    {shortFormat(
                      (record?.scheduleInformation as RecurrentSchedule)
                        .startingOn
                    )}
                  </Typography>
                </Grid>
              )}
            {record?.type === GRANT_REQUEST_TYPE_ENUM.RECURRENT &&
              (record?.scheduleInformation as RecurrentSchedule).endingOn && (
                <Grid item xs={12}>
                  <Typography variant="bodyCopyRegular" color={primary[500]}>
                    End date{" "}
                    {shortFormat(
                      (record?.scheduleInformation as RecurrentSchedule)
                        .endingOn
                    )}
                  </Typography>
                </Grid>
              )}

            {record?.type === GRANT_REQUEST_TYPE_ENUM.RECURRENT &&
              (record?.scheduleInformation as RecurrentSchedule)
                .nextExecutionDate && (
                <Grid item xs={12}>
                  <Typography variant="bodyCopyRegular" color={primary[500]}>
                    Next execution date{" "}
                    {shortFormat(
                      (record?.scheduleInformation as RecurrentSchedule)
                        .nextExecutionDate
                    )}
                  </Typography>
                </Grid>
              )}
            {record?.type === GRANT_REQUEST_TYPE_ENUM.RECURRENT &&
              (record?.scheduleInformation as RecurrentSchedule)
                .lastExecutionDate && (
                <Grid item xs={12}>
                  <Typography variant="bodyCopyRegular" color={primary[500]}>
                    Previous execution date{" "}
                    {shortFormat(
                      (record?.scheduleInformation as RecurrentSchedule)
                        .lastExecutionDate
                    )}
                  </Typography>
                </Grid>
              )}
            {record?.type === GRANT_REQUEST_TYPE_ENUM.RECURRENT &&
              (record?.scheduleInformation as RecurrentSchedule).frequency && (
                <Grid item xs={12}>
                  <Typography variant="bodyCopyRegular" color={primary[500]}>
                    Execution frequency{" "}
                    {
                      (record?.scheduleInformation as RecurrentSchedule)
                        .frequency
                    }
                  </Typography>
                </Grid>
              )}
            {record?.type === GRANT_REQUEST_TYPE_ENUM.RECURRENT &&
              (
                record.scheduleInformation as RecurrentSchedule
              ).executionDates.map((date) => (
                <Grid item xs={12} md={3}>
                  <Typography variant="bodyCopyRegular" color={primary[500]}>
                    {shortFormat(date)}
                  </Typography>
                </Grid>
              ))}
            {record?.type === GRANT_REQUEST_TYPE_ENUM.ONETIME && (
              <Grid item xs={12}>
                <Typography color={primary}>
                  The Grant request must be executed:
                  <Typography variant="bodyCopyRegular" color={primary[500]}>
                    {(record.scheduleInformation as OnTimeSchedule).now
                      ? "immediately"
                      : shortFormat(
                          (record.scheduleInformation as OnTimeSchedule)
                            .executionDate
                        )}
                  </Typography>
                </Typography>
              </Grid>
            )}
            {((record?.type === GRANT_REQUEST_TYPE_ENUM.ONETIME &&
              !(record.scheduleInformation as OnTimeSchedule).now) ||
              record?.type === GRANT_REQUEST_TYPE_ENUM.RECURRENT) &&
              record?.status !== GRANT_REQUEST_STATUS_ENUM.PENDING && (
                <>
                  <Grid item xs={12}>
                    <EvaluateScheduledGrantRequestModal
                      visible={openProcess}
                      close={setOpenProcess}
                      refresh={refetch}
                      data={processDecision}
                    />
                    <Typography variant="bodyCopyBold" color={primary}>
                      Transaction List{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {record.scheduledGrantRequests?.length !== 0 && (
                      <DataGrid
                        {...dataGridProps}
                        rows={record.scheduledGrantRequests as any}
                        getRowId={(row) => row.scheduledGrantRequestId}
                        columns={columns}
                        autoHeight
                        loading={isLoading}
                      />
                    )}
                  </Grid>
                </>
              )}
            {record?.acknowledgement && (
              <>
                <Grid item xs={12}>
                  <Typography variant="bodyCopyBold" color={primary}>
                    Acknowledgment information{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={record.acknowledgement.anonymous}
                        disabled
                      />
                    }
                    label="User want to be anonyms"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={record.acknowledgement.visibility}
                        disabled
                      />
                    }
                    label="Visibility"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={record.acknowledgement.showFundName}
                        disabled
                      />
                    }
                    label="Show Fund Name"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={record.acknowledgement.showDonorAdress}
                        disabled
                      />
                    }
                    label="Show Donner Address"
                  />
                </Grid>
                {record?.acknowledgement.inHonorOf && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      disabled
                      value={record?.acknowledgement.inHonorOf}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="In Honor of"
                    />
                  </Grid>
                )}
                {record?.acknowledgement.inMemoryOf && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      disabled
                      value={record?.acknowledgement.inMemoryOf}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="In Memory of"
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    value={record?.grantPurpose?.type}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Grant Purpose Type"
                  />
                </Grid>
                {record?.grantPurpose?.type === GRANT_PURPOSE_ENUM.SPECIFIC &&
                  record.grantPurpose.note && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        disabled
                        value={record.grantPurpose?.note}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Grant Purpose Note"
                      />
                    </Grid>
                  )}
                {record?.acknowledgement.thirdPartiesEmails &&
                  record?.acknowledgement.thirdPartiesEmails.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="bodyCopyBold" color={primary}>
                          Third Parties Emails
                        </Typography>
                      </Grid>
                      {record?.acknowledgement.thirdPartiesEmails.map(
                        (email) => (
                          <Grid item xs={12} md={6}>
                            <TextField
                              disabled
                              value={email}
                              margin="normal"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              type="text"
                            />
                          </Grid>
                        )
                      )}
                    </>
                  )}
              </>
            )}
            {record?.rejectionReason && (
              <Grid item xs={12} md={6}>
                <TextField
                  disabled
                  value={record.rejectionReason}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Rejection reason"
                />
              </Grid>
            )}
            {!isLoading &&
              investmentRequestData &&
              investmentRequestData?.data.data.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="bodyCopyBold" color={primary}>
                    Related investment requests{" "}
                  </Typography>
                </Grid>
              )}
            {investmentRequestData &&
              investmentRequestData?.data.data.length > 0 &&
              investmentRequestData?.data.data.map(
                (invReq: IInvestmentRequest, index: number) => (
                  <Grid item xs={12} md={6}>
                    <Typography color={primary}>
                      {index + 1}- The{" "}
                      <Link
                        onClick={() =>
                          navigate(
                            `/investment-requests/${invReq.status.toLowerCase()}/${
                              invReq.investmentRequestId
                            }`,
                            {
                              replace: true,
                            }
                          )
                        }
                        target="_blank"
                        underline="always"
                        color={"inherit"}
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        request
                      </Link>{" "}
                      status is{" "}
                      <Typography
                        variant="bodyCopyRegular"
                        color={primary[500]}
                      >
                        {" "}
                        {invReq?.status}
                      </Typography>
                    </Typography>
                  </Grid>
                )
              )}

            <Grid item xs={12} md={12} textAlign={"end"}>
              <EvaluateGrantRequestModal
                visible={open}
                close={setOpen}
                data={decision}
              />
              {record?.status === "PENDING" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",

                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      style={{ marginRight: "0.5rem" }}
                      color="primary"
                      onClick={() => {
                        setOpen(true);
                        setDecision({
                          grantRequest: record,
                          value: "APPROVED",
                        });
                      }}
                    >
                      Accept
                    </Button>
                    <Button
                      size="large"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setOpen(true);
                        setDecision({
                          grantRequest: record,
                          value: "REJECTED",
                        });
                      }}
                    >
                      Reject
                    </Button>
                  </Box>
                </>
              )}

              {record?.status === "APPROVED" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",

                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    size="large"
                    variant="contained"
                    style={{ marginRight: "0.5rem" }}
                    color="primary"
                    onClick={() => {
                      setOpen(true);
                      setDecision({
                        grantRequest: record,
                        value: "PROCESSED",
                      });
                    }}
                  >
                    Process
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>{" "}
        </>
      )}
    </Show>
  );
};
