import React from "react";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import { List, ShowButton, useDataGrid } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import {
  GRANT_REQUEST_STATUS_ENUM,
  GRANT_REQUEST_TYPE_ENUM,
  IGrantRequest,
  OnTimeSchedule,
  RecurrentSchedule,
} from "interfaces/grant-requests";
import { shortFormat } from "utils/date-format";

export const GrantRequestsList: React.FC<
  IResourceComponentsProps & {
    status: GRANT_REQUEST_STATUS_ENUM;
  }
> = ({ status }) => {
  const { dataGridProps } = useDataGrid<IGrantRequest>({
    queryOptions: { enabled: false },
  });
  const { data, isLoading } = useCustom({
    url: `grant-requests`,
    method: "get",
    config: {
      query: {},
      filters: [
        {
          field: "filter[status]",
          operator: "eq",
          value: status,
        },
      ],
    },
  });
  const navigate = useNavigate();
  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "grantRequestId",
        flex: 1,
        headerName: "ID",
        minWidth: 80,
        renderCell: function render({ value }: any) {
          return value.grantRequestId;
        },
      },
      {
        field: "charity",
        flex: 1,
        headerName: "Charity (EIN)",
        minWidth: 100,
        renderCell: function render({ value }: any) {
          return value.ein;
        },
      },
      {
        field: "fundAccount",
        flex: 1,
        headerName: "Fund Name",
        minWidth: 100,
        renderCell: function render({ value }: any) {
          return value.fundAccountName;
        },
      },
      {
        field: "user",
        flex: 1,
        headerName: "Donar",
        minWidth: 200,
        renderCell: function render({ value }: any) {
          return `${value.firstName} ${value.lastName}`;
        },
      },
      {
        field: "type",
        flex: 1,
        headerName: "type",
        minWidth: 100,
      },
      {
        field: "status",
        flex: 1,
        headerName: "status",
        minWidth: 100,
      },
      {
        field: "transactionType",
        flex: 1,
        headerName: "Transaction Type",
        minWidth: 100,
      },
      {
        field: "executionDate",
        flex: 1,
        headerName: "Execution Date",
        minWidth: 100,
        renderCell: function render({ row }) {
          if (row.type === GRANT_REQUEST_TYPE_ENUM.ONETIME) {
            return shortFormat(
              (row.scheduleInformation as OnTimeSchedule).executionDate
            );
          } else {
            const scheduleInformation =
              row?.scheduleInformation as RecurrentSchedule;
            return shortFormat(
              scheduleInformation.nextExecutionDate
                ? scheduleInformation.nextExecutionDate
                : scheduleInformation.lastExecutionDate
            );
          }
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <ShowButton
                hideText
                onClick={() =>
                  navigate(`/grant-requests/${row.grantRequestId}`)
                }
              />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [navigate]
  );

  return (
    <List
      headerProps={{
        sx: {
          textTransform: "capitalize",
        },
      }}
      title={`${status.toLowerCase()} Grant Requests`}
    >
      {!isLoading && (
        <DataGrid
          {...dataGridProps}
          rows={data?.data?.data}
          getRowId={(row) => row.grantRequestId}
          columns={columns}
          autoHeight
          loading={isLoading}
        />
      )}
    </List>
  );
};
