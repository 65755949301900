import { HttpError } from "@refinedev/core";
import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async (reqConfig) => {
  const { idToken } =
    (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};
  reqConfig.headers = { ...reqConfig.headers };
  reqConfig.headers.Authorization = `Bearer ${idToken?.toString() as string}`;
  return reqConfig;
});
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

export { axiosInstance };
