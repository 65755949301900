import React from "react";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import { DateField, List, ShowButton, useDataGrid } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IContributionTransaction } from "interfaces/contribution-transaction";
import { useNavigate } from "react-router-dom";
export const PendingContributionTransactionList: React.FC<
  IResourceComponentsProps
> = () => {
  const { dataGridProps } = useDataGrid<IContributionTransaction>({
    queryOptions: { enabled: false },
  });
  const { data, isLoading } = useCustom({
    url: `contribution-transactions`,
    method: "get",
    config: {
      filters: [
        {
          field: "status",
          operator: "eq",
          value: "PENDING",
        },
      ],
    },
  });
  const navigate = useNavigate();
  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "contributionId",
        flex: 1,
        headerName: "ID",

        renderCell: function render({ value }: any) {
          return value.fundAccountName;
        },
      },
      {
        field: "fund",
        flex: 1,
        headerName: "Fund Name",

        renderCell: function render({ value }: any) {
          return value.fundAccountName;
        },
      },
      {
        field: "contributionReport",
        flex: 1,
        headerName: "Donor",

        renderCell: function render({ value }: any) {
          return value.name;
        },
      },
      {
        field: "type",
        flex: 1,
        headerName: "Contribution type",
      },
      {
        field: "status",
        flex: 1,
        headerName: "Contribution status",
      },

      {
        field: "createdAt",
        flex: 1,
        headerName: "Created Date",
        minWidth: 250,
        renderCell: function render({ value }: any) {
          return <DateField value={value} />;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <ShowButton
              hideText
              onClick={() =>
                navigate(
                  `/contribution-transactions/pending/${row.contributionId}`
                )
              }
            />
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [navigate]
  );

  return (
    <List title="Contribution transactions">
      {!isLoading && (
        <DataGrid
          {...dataGridProps}
          rows={data?.data?.data}
          getRowId={(row) => row.contributionId}
          columns={columns}
          autoHeight
          loading={isLoading}
        />
      )}
    </List>
  );
};
