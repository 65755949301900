import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  listItemClasses,
} from "@mui/material";
import { warm, neutral, primary } from "style/color";
import { shortFormat } from "utils/date-format";
import { moneyFormat } from "utils/money-format";

import { ReactComponent as EyeIcon } from "./../../assets/icons/eye.svg";
import { ReactComponent as EyeClosedIcon } from "./../../assets/icons/eye-closed.svg";

import { useCustom } from "@refinedev/core";
import {
  FUND_MOVEMENT_TYPE_ENUM,
  IFundMovementHistory,
} from "interfaces/fund-movements-history";
import { useNavigate } from "react-router-dom";
import PaginatedEntityList from "interfaces/PaginatedEntityList";
import { InView } from "react-intersection-observer";

export const Home: React.FC = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [movementsData, setMovementsData] = useState<IFundMovementHistory[]>(
    []
  );

  const typesFilter = [
    { title: "Contributions", status: FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION },
    { title: "Grants", status: FUND_MOVEMENT_TYPE_ENUM.GRANT },
    { title: "Investments", status: FUND_MOVEMENT_TYPE_ENUM.INVESTMENT },
    { title: "GrantVestments", status: FUND_MOVEMENT_TYPE_ENUM.GRANTVESTMENT },
  ];
  const [types, setTypes] = React.useState<string[]>([
    FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION,
    FUND_MOVEMENT_TYPE_ENUM.GRANT,
    FUND_MOVEMENT_TYPE_ENUM.INVESTMENT,
    FUND_MOVEMENT_TYPE_ENUM.GRANTVESTMENT,
  ]);

  const {
    data: paginatedMovementsData,
    isSuccess: isGetMovementSuccess,
    isFetching: isGetMovementsLoading,
  } = useCustom({
    url: `fund/movements/all`,
    method: "get",
    config: {
      query: {
        limit: limit,
        offset: offset,
        ...{ "filter[types]": [...types], "filter[status]": ["PENDING"] },
      },
    },
  });
  const GoToDetailsPage = (id: number, type: FUND_MOVEMENT_TYPE_ENUM) => {
    let url = "";
    switch (type) {
      case FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION:
        url = `/contribution-transactions/pending/${id}`;
        break;
      case FUND_MOVEMENT_TYPE_ENUM.INVESTMENT:
        url = `/investment-requests/pending/${id}`;
        break;
      case FUND_MOVEMENT_TYPE_ENUM.GRANTVESTMENT:
        url = `/grant-vestments-requests/status/pending`;
        break;
      case FUND_MOVEMENT_TYPE_ENUM.GRANT:
        url = `/grant-requests/${id}`;
        break;
    }
    url = url.replace(":id", id.toString());
    navigate(url);
  };

  const contains = (type: FUND_MOVEMENT_TYPE_ENUM) => {
    return types.includes(type);
  };
  const handleTypeChange = (type: FUND_MOVEMENT_TYPE_ENUM) => {
    setTypes((prev) => {
      const index = prev.findIndex((item) => item === type);
      let filterTypes = [];

      if (index !== -1) {
        filterTypes = [...prev];
        filterTypes.splice(index, 1);
      } else {
        filterTypes = [...prev, type];
      }
      return filterTypes;
    });
  };

  useEffect(() => {
    if (paginatedMovementsData) {
      if (
        (
          paginatedMovementsData.data as PaginatedEntityList<IFundMovementHistory>
        )?.pagination?.offset === 0
      ) {
        setMovementsData(
          (
            paginatedMovementsData.data as PaginatedEntityList<IFundMovementHistory>
          ).data
        );
      } else {
        setMovementsData([
          ...movementsData,
          ...(
            paginatedMovementsData.data as PaginatedEntityList<IFundMovementHistory>
          ).data,
        ]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedMovementsData]);

  const fetchData = (inView: boolean) => {
    if (inView && paginatedMovementsData?.data?.pagination.nextOffset)
      setOffset(paginatedMovementsData?.data?.pagination.nextOffset);
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ color: `neutral.main`, p: 1 }} variant="subtitle1">
            Pending Requests
          </Typography>
        </Box>
        <Grid sx={{ my: 1 }} container spacing={2}>
          {typesFilter.map((item, index) => {
            return (
              <Grid key={index} item>
                {contains(item.status) ? (
                  <Button
                    key={index}
                    onClick={() => handleTypeChange(item.status)}
                    startIcon={<EyeIcon />}
                    variant={"contained"}
                    color="primary"
                    size="large"
                  >
                    {item.title}
                  </Button>
                ) : (
                  <Button
                    key={index}
                    onClick={() => handleTypeChange(item.status)}
                    startIcon={<EyeClosedIcon />}
                    variant={"outlined"}
                    color="primary"
                    size="large"
                  >
                    {item.title}
                  </Button>
                )}
              </Grid>
            );
          })}
        </Grid>

        <Box marginTop={4}>
          {isGetMovementSuccess && (
            <>
              <Grid container>
                <List
                  className="successor"
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",

                    [` & .${listItemClasses.root}:hover`]: {
                      backgroundColor: warm[200],
                      cursor: "pointer",
                    },
                  }}
                >
                  {movementsData &&
                    movementsData.length > 0 &&
                    movementsData.map(
                      //TODO: Refactor in a seperate card
                      (movement: IFundMovementHistory, index: number) => {
                        return (
                          <>
                            <ListItem
                              key={index}
                              onClick={() =>
                                GoToDetailsPage(
                                  movement.type !==
                                    FUND_MOVEMENT_TYPE_ENUM.GRANT
                                    ? movement.id
                                    : movement.grantMainId,
                                  movement.type
                                )
                              }
                            >
                              <Grid
                                alignItems="center"
                                container
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  px: 2,
                                }}
                              >
                                <Grid item xs={12} sm={3} textAlign={"start"}>
                                  <ListItemText
                                    sx={{
                                      display: "inline-grid",
                                      width: "auto",
                                      flex: "none",
                                    }}
                                    primary={
                                      <Typography
                                        sx={{ color: `neutral.main` }}
                                        variant="bodyCopyBold"
                                        gutterBottom
                                      >
                                        {`By ${movement.user}`}
                                      </Typography>
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2} textAlign={"start"}>
                                  <Typography
                                    variant="bodyCopyRegular"
                                    sx={{
                                      color: neutral[900],
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {movement.type.toLowerCase()}
                                  </Typography>
                                </Grid>

                                <Grid item xs={12} sm={2} textAlign={"start"}>
                                  <Typography
                                    variant="bodyCopyRegular"
                                    sx={{ color: neutral[600] }}
                                  >
                                    {shortFormat(movement.date)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} textAlign={"start"}>
                                  <Typography
                                    variant="bodyCopyRegular"
                                    sx={{
                                      color: neutral[900],
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {movement.title}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={2} textAlign={"end"}>
                                  <Typography
                                    sx={{
                                      px: 1,
                                      py: 1,

                                      backgroundColor:
                                        movement.type ===
                                        FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION
                                          ? primary[50]
                                          : "transparent",
                                    }}
                                    variant="bodyCopyBold"
                                    gutterBottom
                                  >
                                    <>
                                      {(movement.type ===
                                        FUND_MOVEMENT_TYPE_ENUM.GRANT ||
                                        movement.type ===
                                          FUND_MOVEMENT_TYPE_ENUM.GRANTVESTMENT ||
                                        (movement.type ===
                                          FUND_MOVEMENT_TYPE_ENUM.INVESTMENT &&
                                          movement.transactionType ===
                                            "BUY")) &&
                                        "-"}
                                      ${moneyFormat(movement.amount)}
                                    </>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </ListItem>

                            <Divider key={index} />
                          </>
                        );
                      }
                    )}
                </List>
              </Grid>
            </>
          )}

          {isGetMovementsLoading ? (
            <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          ) : paginatedMovementsData?.data?.pagination.hasNext ? (
            <InView as="div" onChange={(inView) => fetchData(inView)}></InView>
          ) : null}
          {!isGetMovementsLoading && movementsData.length === 0 && (
            <Typography variant="subtitle3">No investment found.</Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
