export const shortFormat = (date: Date | undefined) => {
  if (date)
    return new Date(date)?.toLocaleDateString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
};
export const longDateFormat = (date: Date | string) => {
  return new Date(date)?.toLocaleDateString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  });
};
export const numberOfDays = (
  date1: Date | string | undefined,
  date2?: Date | string | undefined
) => {
  if (date1) {
    const date = date2 ? new Date(date2) : new Date();
    const Difference_In_Time = new Date(date1).getTime() - date.getTime();
    const daysLeft = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    return daysLeft;
  }
  return 0;
};
export const numberRange = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const getLast14Dates = () => {
  const dates = [];
  for (let i = 1; i <= 14; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    dates.push(date.toISOString().split("T")[0]); // Format: YYYY-MM-DD
  }
  return dates.reverse();
};
export const isSameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth()
  );
};
