import { ICharity } from "./charity";
import { IFundAccount } from "./fund-account";
import { IUser } from "./user";

export enum GRANT_REQUEST_STATUS_ENUM {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED",
  FULFILLED = "FULFILLED",
  STOPPED = "STOPPED",
  SCHEDULED = "SCHEDULED",
  PROCESSED = "PROCESSED",
}

export enum GRANT_REQUEST_TYPE_ENUM {
  ONETIME = "ONETIME",
  RECURRENT = "RECURRENT",
}

export enum GRANT_REQUEST_TRANSACTION_TYPE_ENUM {
  CASH = "CASH",
  INVESTMENT = "INVESTMENT",
}

export interface CashInfo {
  requestedAmount: number;
  appliedAmount: number;
}

export enum INVESTMENT_REQUEST_INTEREST_ENUM {
  UNITS = "UNITS",
  AMOUNT = "AMOUNT",
}

export enum GRANT_FREQUENCY_ENUM {
  YEARLY = "YEARLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
}

export interface InvestmentInfo {
  requestedAmount: number;
  appliedAmount: number;
  interestedIn: INVESTMENT_REQUEST_INTEREST_ENUM;
  units: number;
  ticker: string;
}
export interface RecurrentSchedule {
  startingOn: Date;
  endingOn: Date;
  frequency: GRANT_FREQUENCY_ENUM;
  lastExecutionDate?: Date;
  nextExecutionDate: Date;
  executionDates: Date[];
}

export interface OnTimeSchedule {
  executionDate: Date;
  now: boolean;
}

export interface Acknowledgement {
  anonymous: boolean;
  showFundName: boolean;
  showDonorAdress: boolean;
  donorAddress: string;
  inHonorOf?: string;
  inMemoryOf?: string;
  thirdPartiesEmails: string[];
  visibility: boolean;
}

export enum GRANT_PURPOSE_ENUM {
  UNRESTRICTED = "UNRESTRICTED",
  SPECIFIC = "SPECIFIC",
}

export interface GrantPurpose {
  type: GRANT_PURPOSE_ENUM;
  note?: string;
}

export enum SCHEDULED_GRANT_REQUEST_STATUS_ENUM {
  PROCESSED = "PROCESSED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
}
export interface IScheduledGrantRequest {
  scheduledGrantRequestId: number;
  grantRequest: IGrantRequest;
  executionDate: Date;
  status: SCHEDULED_GRANT_REQUEST_STATUS_ENUM;
  receipt?: { url: string; fileName: string };
}
export interface IGrantRequest {
  grantRequestId: number;
  charity: ICharity;
  fundAccount: IFundAccount;
  user: IUser;
  status: GRANT_REQUEST_STATUS_ENUM;
  type: GRANT_REQUEST_TYPE_ENUM;
  transactionType: GRANT_REQUEST_TRANSACTION_TYPE_ENUM;
  toInform: string;
  information: CashInfo | InvestmentInfo;
  scheduleInformation: RecurrentSchedule | OnTimeSchedule;
  acknowledgement: Acknowledgement;
  grantPurpose: GrantPurpose;
  rejectionReason?: string;
  updatedAt: Date;
  createdAt: Date;
  scheduledGrantRequests?: IScheduledGrantRequest[];
}
