import React from "react";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import { List, ShowButton, useDataGrid } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IInvestmentRequest } from "interfaces/investment-requests";
import { moneyFormat } from "utils/money-format";
import { useNavigate } from "react-router-dom";

export const InvestmentRequestsPendingList: React.FC<
  IResourceComponentsProps
> = () => {
  const { dataGridProps } = useDataGrid<IInvestmentRequest>({
    queryOptions: { enabled: false },
  });
  const { data, isLoading } = useCustom({
    url: `investments-requests`,
    method: "get",
    config: {
      query: {},
      filters: [
        {
          field: "filter[status]",
          operator: "eq",
          value: "PENDING",
        },
      ],
    },
  });
  const navigate = useNavigate();
  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "investmentRequestId",
        flex: 1,
        headerName: "ID",
        maxWidth: 40,
        renderCell: function render({ value }: any) {
          return value.investmentRequestId;
        },
      },
      {
        field: "investment",
        flex: 1,
        headerName: "Investment",
        minWidth: 100,
        renderCell: function render({ value }: any) {
          return value.ticker;
        },
      },
      {
        field: "fund",
        flex: 1,
        headerName: "Fund Name",
        minWidth: 100,
        renderCell: function render({ value }: any) {
          return value.fundAccountName;
        },
      },
      {
        field: "user",
        flex: 1,
        headerName: "Investor",
        minWidth: 100,
        renderCell: function render({ value }: any) {
          return `${value.firstName} ${value.lastName}`;
        },
      },
      {
        field: "type",
        flex: 1,
        headerName: "type",
        maxWidth: 80,
      },

      {
        field: "requestedAmount",
        flex: 1,
        headerName: "Requested Amount",
        minWidth: 100,
        renderCell: function render({ value }: any) {
          return <>${moneyFormat(value, 2)}</>;
        },
      },
      {
        field: "appliedAmount",
        flex: 1,
        headerName: "Applied Amount",
        minWidth: 100,
        renderCell: function render({ value }: any) {
          return <>${moneyFormat(value, 2)}</>;
        },
      },
      {
        field: "requestedUnits",
        flex: 1,
        headerName: "Requested Units",
        minWidth: 100,
        renderCell: function render({ value }: any) {
          return <>{moneyFormat(value, 4)} unit(s)</>;
        },
      },

      {
        field: "appliedUnits",
        flex: 1,
        headerName: "Applied Units",
        minWidth: 100,
        renderCell: function render({ value }: any) {
          return <>{moneyFormat(value, 4)} unit(s)</>;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <ShowButton
              hideText
              onClick={() =>
                navigate(
                  `/investment-requests/pending/${row.investmentRequestId}`
                )
              }
            />
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [navigate]
  );

  return (
    <List title="Pending Investment Requests">
      {!isLoading && (
        <DataGrid
          {...dataGridProps}
          rows={data?.data?.data}
          getRowId={(row) => row.investmentRequestId}
          columns={columns}
          autoHeight
          loading={isLoading}
        />
      )}
    </List>
  );
};
