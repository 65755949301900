import { Create } from "@refinedev/mui";
import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps } from "@refinedev/core";
import {
  INVESTMENT_FORMAT_ENUM,
  INVESTMENT_RISK_TYPE_ENUM,
} from "interfaces/investment";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import { useState } from "react";

export const InvestmentCreate: React.FC<IResourceComponentsProps> = () => {
  const [disable, setDisable] = useState(false);
  const {
    refineCore: { formLoading, onFinish },
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm({
    refineCoreProps: {
      errorNotification: (error) => {
        return {
          message: error?.message?.includes("Ticker not found")
            ? "The Ticker is not found in the listed investments."
            : "Oops! something went wrong, please try later.",
          type: "error",
        };
      },
    },
  });
  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        onClick: (e: React.BaseSyntheticEvent) => {
          setValue("dailyChange", 0);
          const marketPrice = parseFloat(
            getValues("marketPrice").toString().replace(",", "")
          );
          setValue("marketPrice", marketPrice);
          handleSubmit(onFinish, () => false)(e);
        },
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <TextField
              {...register("ticker", {
                required: "This ticker name is required",
              })}
              error={!!(errors as any)?.ticker}
              helperText={(errors as any)?.ticker?.message}
              margin="normal"
              fullWidth
              onChange={(e) => setValue("ticker", e.target.value.toUpperCase())}
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Tikcer"
              name="ticker"
            />
            <Grid container xs={12} md={12}>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  label="Is Listed"
                  control={
                    <Checkbox
                      {...register("isListed")}
                      onChange={() => {
                        setDisable(true);
                        clearErrors(["name", "description"]);
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  label="Is Active"
                  control={
                    <Checkbox {...register("isActive")} defaultChecked />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  label="Is Liquid"
                  control={
                    <Checkbox {...register("isLiquid")} defaultChecked />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  label="Is Available For Purchase"
                  control={<Checkbox {...register("isAFP")} defaultChecked />}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              {...register("name", {
                validate: (value, formValues) => {
                  if (!formValues.isListed && !value) {
                    return "Name is required in case of unlisted investments";
                  }
                },
              })}
              error={!!(errors as any)?.name}
              helperText={(errors as any)?.name?.message}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Name"
              name="name"
              disabled={disable}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              {...register("description", {
                validate: (value, formValues) => {
                  if (!formValues.isListed && !value) {
                    return "Description is required in case of unlisted investments";
                  }
                },
              })}
              error={!!(errors as any)?.description}
              helperText={(errors as any)?.description?.message}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="textarea"
              minRows={3}
              rows={2}
              maxRows={4}
              multiline
              label="Description"
              name="description"
              disabled={disable}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              {...register("riskType", {
                required: "This riskType is required",
              })}
              error={!!(errors as any)?.riskType}
              helperText={(errors as any)?.riskType?.message}
              select
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={INVESTMENT_RISK_TYPE_ENUM.EQUITY}
              label="Risk Type"
              name="riskType"
            >
              {[
                {
                  value: INVESTMENT_RISK_TYPE_ENUM.EQUITY,
                  label: "Equity",
                },
                {
                  value: INVESTMENT_RISK_TYPE_ENUM.FIXED_INCOME,
                  label: "Fixed income",
                },
                {
                  value: INVESTMENT_RISK_TYPE_ENUM.OTHER,
                  label: "Other",
                },
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              {...register("format", {
                required: "This format is required",
              })}
              error={!!(errors as any)?.format}
              helperText={(errors as any)?.format?.message}
              select
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={INVESTMENT_FORMAT_ENUM.SHARES}
              label="Format"
              name="format"
            >
              {[
                {
                  value: INVESTMENT_FORMAT_ENUM.SHARES,
                  label: "Shares",
                },
                {
                  value: INVESTMENT_FORMAT_ENUM.ETF,
                  label: "ETF- Exchange Traded Funds",
                },
                {
                  value: INVESTMENT_FORMAT_ENUM.MUTUAL_FUNDS,
                  label: "Mutual Funds",
                },
                {
                  value: INVESTMENT_FORMAT_ENUM.BOND,
                  label: "Bond",
                },
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              {...register("marketPrice", {
                validate: (value, formValues) => {
                  if (!formValues.isListed && !value) {
                    return "MarketPrice is required in case of unlisted investments";
                  }
                },
              })}
              error={!!(errors as any)?.marketPrice}
              helperText={(errors as any)?.marketPrice?.message}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputComponent: NumericFormatCustom as any,
              }}
              label="Market Price"
              name="marketPrice"
              disabled={disable}
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
