import { Create, useAutocomplete } from "@refinedev/mui";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import { useCallback, useMemo, useState } from "react";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";

export const ContributionTransactionCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const {
    refineCore: { formLoading, onFinish },
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [selectedUserSub, setSelectedUserSub] = useState("");
  const [selectedFund, setSelectedFund] = useState("");
  const { autocompleteProps: users } = useAutocomplete({
    resource: "Users",
  });
  const userOptions = useMemo(
    () =>
      users.options?.map((user, index) => {
        return {
          sub: user.sub,
          label: `${index + 1}- ${user.firstName} ${user.lastName}`,
        };
      }),
    [users.options]
  );

  const { data: userFunds, isLoading: userFundsLoading } = useCustom({
    url: `users/sub`,
    method: "get",
    config: {
      query: {
        sub: selectedUserSub,
      },
    },
    queryOptions: {
      enabled: !!selectedUserSub,
    },
  });

  const userFundsOptions = useMemo(
    () =>
      userFunds?.data.fundAccounts.map(
        (fundAccountMember: any, index: number) => {
          return {
            fundId: fundAccountMember.fund.fundAccountId,
            label: `${index + 1}- ${fundAccountMember.fund.fundAccountName}`,
          };
        }
      ),
    [userFunds?.data.fundAccounts]
  );
  const fillContributionReport = useCallback(
    (fundId: string) => {
      const user = users.options.find((item) => (item.sub = selectedUserSub));

      const selectedUserFund = userFunds?.data.fundAccounts
        .filter((fund: any) => fund.fund.fundAccountId === fundId)
        .at();
      setValue("donorEmail", user?.email);
      if (selectedUserFund.entityInformation) {
        setValue("name", selectedUserFund.entityInformation?.entityName);
        setValue("city", selectedUserFund.entityInformation?.city);
        setValue("zip", selectedUserFund.entityInformation?.zip);
        setValue("state", selectedUserFund.entityInformation?.state);
        setValue(
          "addressLine1",
          selectedUserFund.entityInformation?.firstAddressLine
        );
        setValue(
          "addressLine2",
          selectedUserFund.entityInformation?.secondAddressLine
        );
      } else {
        setValue("name", `${user.firstName} ${user.lastName}`);
        setValue("city", user.city);
        setValue("zip", user.zip);
        setValue("state", user.state);
        setValue("addressLine1", user.addressLine1);
        setValue("addressLine2", user.addressLine2);
      }
    },
    [selectedUserSub, setValue, userFunds?.data.fundAccounts, users.options]
  );

  return (
    <Create
      resource="/contribution-transactions"
      title={
        <Typography variant="body1" fontWeight="bold">
          Create Contribution on behalf of the user
        </Typography>
      }
      isLoading={formLoading}
      saveButtonProps={{
        resource: "/contribution-transactions",
        onClick: (e: React.BaseSyntheticEvent) => {
          setValue("sub", selectedUserSub);
          setValue("fundId", selectedFund);
          setValue("type", "OTHER");
          const contributionInformation = {
            estimatedAmount: getValues("estimatedAmount") as number,
            description: getValues("description"),
          };
          setValue("contributionInformation", contributionInformation);
          const contributionReport = {
            name: getValues("name"),
            donorEmail: getValues("donorEmail"),
            addressLine1: getValues("addressLine1"),
            addressLine2: getValues("addressLine2"),
            city: getValues("city"),
            state: getValues("state"),
            zip: getValues("zip"),
          };
          setValue("contributionReport", contributionReport);
          handleSubmit(onFinish, () => false)(e);
        },
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Typography variant="body1" color="primary" fontWeight="bold">
          Please select a user
        </Typography>
        {!users.loading && userOptions && (
          <Autocomplete
            disablePortal
            id="user-select"
            options={userOptions}
            onChange={(_, value) => {
              setSelectedUserSub(value?.sub);
            }}
            isOptionEqualToValue={(option, value) => option.sub === value.sub}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Please chose or type user name"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                {...register("user", {
                  required: "Please Choose a user",
                })}
                error={!!(errors as any)?.user}
                helperText={(errors as any)?.user?.message}
              />
            )}
          />
        )}
        {!userFundsLoading && userFunds && (
          <>
            <Typography
              variant="body1"
              color="primary"
              fontWeight="bold"
              sx={{ mt: 2 }}
            >
              Please select one of the user's fund accounts
            </Typography>
            <Autocomplete
              disablePortal
              id="user-fund-select"
              options={userFundsOptions}
              onChange={(_, value: any) => {
                setSelectedFund(value?.fundId);
                fillContributionReport(value?.fundId);
              }}
              isOptionEqualToValue={(option, value) =>
                option.fundId === value.fundId
              }
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.label}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Please chose a fun account"
                  inputProps={{
                    ...params.inputProps,

                    autoComplete: "new-password",
                  }}
                  {...register("fund", {
                    required: "Please Choose a fund",
                  })}
                  error={!!(errors as any)?.fund}
                  helperText={(errors as any)?.fund?.message}
                />
              )}
            />
            <Typography
              variant="body1"
              color="primary"
              fontWeight="bold"
              sx={{ pt: 2 }}
            >
              Estimated Amount
            </Typography>
            <TextField
              {...register("estimatedAmount", {
                required: "This estimated Amount is required",
              })}
              error={!!(errors as any)?.estimatedAmount}
              helperText={(errors as any)?.estimatedAmount?.message}
              margin="normal"
              fullWidth
              sx={{ mt: 0 }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
              name="estimatedAmount"
            />
            <Typography
              variant="body1"
              color="primary"
              fontWeight="bold"
              sx={{ pt: 2 }}
            >
              Description{" "}
            </Typography>
            <TextField
              {...register("description", {
                required: "This description is required",
              })}
              error={!!(errors as any)?.description}
              helperText={(errors as any)?.description?.message}
              margin="normal"
              fullWidth
              sx={{ mt: 0 }}
              InputLabelProps={{ shrink: true }}
              type="textarea"
              multiline={true}
              rows={2}
              name="description"
            />
            <Typography
              variant="body1"
              color="primary"
              fontWeight="bold"
              sx={{ pt: 2 }}
            >
              Reference
            </Typography>
            <TextField
              {...register("reference", {
                required: "This reference is required",
              })}
              error={!!(errors as any)?.reference}
              helperText={(errors as any)?.reference?.message}
              margin="normal"
              fullWidth
              sx={{ mt: 0 }}
              InputLabelProps={{ shrink: true }}
              type="text"
              name="reference"
            />
            <Typography
              variant="body1"
              color="primary"
              fontWeight="bold"
              sx={{ pt: 2 }}
            >
              Extra Notes{" "}
            </Typography>
            <TextField
              {...register("extraNotes")}
              margin="normal"
              fullWidth
              sx={{ mt: 0 }}
              InputLabelProps={{ shrink: true }}
              type="textarea"
              multiline={true}
              rows={3}
              name="extraNotes"
            />
            <Typography
              variant="body1"
              color="primary"
              fontWeight="bold"
              sx={{ pt: 2 }}
            >
              Contribution Report
            </Typography>

            <TextField
              {...register("name", {
                required: "The name is required",
              })}
              margin="normal"
              fullWidth
              sx={{ mt: 2 }}
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Name"
              name="name"
            />
            <TextField
              {...register("donorEmail", {
                required: "The email is required",
              })}
              margin="normal"
              fullWidth
              sx={{ mt: 2 }}
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Email"
              name="donorEmail"
            />
            <TextField
              {...register("addressLine1", {
                required: "The addressLine1 is required",
              })}
              margin="normal"
              fullWidth
              sx={{ mt: 2 }}
              InputLabelProps={{ shrink: true }}
              type="text"
              label="First address line"
              name="addressLine1"
            />

            <TextField
              {...register("addressLine2")}
              margin="normal"
              fullWidth
              sx={{ mt: 2 }}
              InputLabelProps={{ shrink: true }}
              type="text"
              name="addressLine2"
              label="Second address line"
            />

            <TextField
              {...register("city", {
                required: "The city is required",
              })}
              margin="normal"
              fullWidth
              sx={{ mt: 1 }}
              InputLabelProps={{ shrink: true }}
              type="text"
              label="City"
              name="city"
            />

            <TextField
              {...register("state", {
                required: "The state is required",
              })}
              margin="normal"
              fullWidth
              sx={{ mt: 2 }}
              InputLabelProps={{ shrink: true }}
              type="text"
              label="State"
              name="state"
            />

            <TextField
              {...register("zip", {
                required: "The zip is required",
              })}
              margin="normal"
              fullWidth
              sx={{ mt: 2 }}
              InputLabelProps={{ shrink: true }}
              type="number"
              label="Zip"
              name="zip"
            />
          </>
        )}
      </Box>
    </Create>
  );
};
