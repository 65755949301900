import { Button, Divider } from "@aws-amplify/ui-react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useCustom, useUpdate } from "@refinedev/core";
import { UpdateInvestmentCard } from "components/card/UpdateInvestmentCard";
import { IInvestment } from "interfaces/investment";
import { useState } from "react";
import { warm } from "style/color";

interface InvestmentBatchUpdateModalProps {
  open: boolean;
  closeModal: () => void;
}
export const InvestmentBatchUpdateModal: React.FC<
  InvestmentBatchUpdateModalProps
> = ({ closeModal, open }) => {
  const {
    data: investments,
    isSuccess: isGetInvestmentSuccess,
    refetch,
  } = useCustom({
    url: `investments-with-history`,
    method: "get",
  });

  const { mutate, isLoading } = useUpdate();
  const [updatedInvestment, setUpdatedInvestment] = useState<
    {
      ticker: string;
      id: number;
      marketPrice: number;
      dailyChange: number;
    }[]
  >([]);

  const updateInvestment = (
    ticker: string,
    id: number,
    marketPrice: number,
    dailyChange: number
  ) => {
    setUpdatedInvestment((prev) => {
      const newData = {
        ticker,
        id,
        marketPrice,
        dailyChange,
      };
      let updated = prev;
      const index = updated.findIndex((item) => item.ticker === ticker);
      if (index === -1) {
        updated.push(newData);
      } else {
        updated[index] = newData;
      }
      return updated;
    });
  };
  const submit = () => {
    mutate(
      {
        resource: "investments-batch-update",
        values: { input: updatedInvestment },
        id: "",
      },
      {
        onSuccess: () => {
          refetch();
          closeModal();
        },
      }
    );
  };
  return (
    <>
      <Dialog open={open} onClose={closeModal} maxWidth="md" fullWidth>
        <DialogTitle sx={{ backgroundColor: warm[200] }}>
          Investment Update
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ backgroundColor: warm[200] }}>
          {isGetInvestmentSuccess && (
            <>
              <Grid container rowSpacing={2}>
                {investments.data.data &&
                  investments.data.data.length > 0 &&
                  investments.data.data.map(
                    (investment: IInvestment, index: number) => (
                      <Grid
                        key={`update-investment-card-key-${index}`}
                        item
                        xs={12}
                      >
                        <UpdateInvestmentCard
                          investment={investment}
                          updateInvestment={updateInvestment}
                        />
                      </Grid>
                    )
                  )}
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: warm[200] }}>
          <Button
            isLoading={isLoading}
            onClick={() => {
              submit();
            }}
          >
            Confirm
          </Button>
          <Button onClick={closeModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
