import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  Grid,
  Link,
} from "@mui/material";

import { warm, neutral } from "style/color";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import LaunchIcon from "@mui/icons-material/Launch";
import { moneyFormat, unitFormat } from "utils/money-format";
import { IInvestment } from "interfaces/investment";
import { EditButton, ShowButton } from "@refinedev/mui";
import { useState } from "react";
import { InvestmentDetailsModal } from "components/modal/investment-details-modal";
import { useNavigate } from "react-router-dom";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { DividendModal } from "components/modal/dividend-modal";

export const InvestmentCard = (investment: IInvestment) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openDividendModal, setDividendModal] = useState(false);
  const { name, ticker, dailyChange, marketPrice, isListed, totalInvestments } = investment;

  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
          ":hover": { backgroundColor: warm[500] },
        }}
      >
        <CardContent
          sx={{
            pl: 3,
            py: 2,
            "&:last-child": { pb: 1 },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid container alignItems={"center"}>
            <Grid item xs={12} sm={4}>
              <Box>
                <Typography variant="bodyCopyBold">{name}</Typography>
                <Typography
                  variant="bodyCopyRegular"
                  sx={{
                    color: neutral[600],
                    display: "flex",
                    alignItems: "center",
                  }}
                  gutterBottom
                >
                  {ticker}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Box>
                <Typography variant="bodyCopyBold">
                  ${moneyFormat(marketPrice, 6)}
                </Typography>
                <Typography
                  variant="bodyCopyRegular"
                  sx={{
                    color: neutral[600],
                    display: "flex",
                    alignItems: "center",
                  }}
                  gutterBottom
                >
                  <ArrowOutwardIcon fontSize="small" />{" "}
                  {dailyChange >= 0
                    ? `${moneyFormat(dailyChange, 2)}`
                    : moneyFormat(dailyChange, 2)}
                  %
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Box>
                <Typography variant="bodyCopyBold">
                  {unitFormat(totalInvestments?.totalAmount || 0)} units
                </Typography>
                <Typography
                  variant="bodyCopyRegular"
                  sx={{
                    color: neutral[600],
                    display: "flex",
                    alignItems: "center",
                  }}
                  gutterBottom
                >
                  Neta total shares
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Link
                target="_blank"
                href={`https://finance.yahoo.com/quote/${ticker}`}
              >
                <Button
                  disabled={!isListed}
                  startIcon={<LaunchIcon fontSize="small" />}
                  variant="text"
                  color="primary"
                  className="charites-more-info"
                >
                  More info
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12} sm={2}>
              <ShowButton hideText onClick={() => setOpen(true)} />
              <EditButton
                hideText
                onClick={() => navigate(`/investments/edit/${ticker}`)}
              />
              <Button
                startIcon={<CurrencyExchangeIcon fontSize="small" />}
                onClick={() => setDividendModal(true)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <InvestmentDetailsModal
        open={open}
        setOpen={setOpen}
        investment={investment}
      />
      <DividendModal
        open={openDividendModal}
        setOpen={setDividendModal}
        ticker={investment.ticker}
      />
    </>
  );
};
