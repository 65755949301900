import React, { useState } from "react";
import {
  CashInformation,
  IContributionTransaction,
  OtherAssetsInformation,
  SecuritiesInformation,
} from "interfaces/contribution-transaction";
import { Show } from "@refinedev/mui";
import {
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useShow } from "@refinedev/core";
import { primary } from "style/color";
import { EvaluateContributionTransactionModal } from "components/modal/evaluate-contribution-transaction";
import { useParams } from "react-router-dom";
import { ProcessContributionTransactionModal } from "components/modal/process-contribution-transaction";
import { moneyFormat } from "utils/money-format";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { shortFormat } from "utils/date-format";
export const ContributionTransactionDetails: React.FC = () => {
  const { id } = useParams();
  const { queryResult } = useShow<IContributionTransaction>({
    resource: "contribution-transactions",
    id: id,
  });
  const [open, setOpen] = useState(false);
  const [openProcess, setOpenProcess] = useState(false);
  const [decision, setDecision] = useState<{
    contributionTransaction: IContributionTransaction | undefined;
    value: "PENDING" | "ACCEPTED" | "REJECTED";
  }>({ contributionTransaction: undefined, value: "PENDING" });
  const { data } = queryResult;
  const record = data?.data;
  return (
    <Show
      isLoading={queryResult.isLoading}
      title={
        <Typography variant="h5" color={primary}>
          Contribution Transaction Details
        </Typography>
      }
    >
      {queryResult?.status === "loading" ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          {data?.data.status === "PROCESSED" && data?.data.receipt?.url && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",

                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              {" "}
              <Link
                href={data?.data.receipt?.url}
                target="__blank"
                underline="none"
                color="inherit"
              >
                {" "}
                <Button
                  fullWidth
                  startIcon={<ArrowCircleDownIcon />}
                  variant="contained"
                >
                  Download
                </Button>{" "}
              </Link>
            </Box>
          )}
          <TextField
            value={`${record?.user?.title ? record?.user?.title : ""} ${
              record?.user?.firstName ? record?.user?.firstName : ""
            } ${record?.user?.middleName ? record?.user?.middleName : ""}${
              record?.user?.lastName ? record.user?.lastName : ""
            } ${record?.user?.suffix ? record?.user?.suffix : ""}`}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="User "
            disabled
          />
          <TextField
            value={`${
              record?.contributionReport?.name
                ? record?.contributionReport?.name
                : ""
            }`}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Donor "
            disabled
          />
          <TextField
            value={`${
              record?.contributionReport?.donorEmail
                ? record?.contributionReport?.donorEmail
                : ""
            }`}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Donor email"
            disabled
          />
          <TextField
            value={`${record?.contributionReport?.addressLine1} ${record?.contributionReport?.addressLine2}, ${record?.contributionReport?.city} ${record?.contributionReport?.state} ${record?.contributionReport?.zip}`}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Donor address"
            disabled
          />
          <TextField
            disabled
            value={record?.fund.fundAccountName}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Fund Name"
          />
          <TextField
            disabled
            value={shortFormat(record?.createdAt)}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Creation Date"
          />
          {record?.status === "REJECTED" && (
            <TextField
              disabled
              value={shortFormat(record?.updatedAt)}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Rejection Date"
            />
          )}
          {record?.status === "CANCELED" && (
            <TextField
              disabled
              value={shortFormat(record?.updatedAt)}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Cancellation Date"
            />
          )}
          {record?.status === "PROCESSED" && (
            <TextField
              disabled
              value={shortFormat(record?.updatedAt)}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Process Date"
            />
          )}
          {record?.status === "ACCEPTED" && (
            <TextField
              disabled
              value={shortFormat(record?.updatedAt)}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Process Date"
            />
          )}
          <TextField
            disabled
            value={record?.status}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Status "
          />
          <TextField
            disabled
            value={record?.type}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Contribution Transaction Type "
          />
          {record?.type === "CASH" && (
            <>
              <TextField
                disabled
                //@ts-ignore
                value={moneyFormat(
                  (record?.contributionInformation as CashInformation)
                    .requestedAmount
                )}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Requested Amount"
              />
              {record?.contributionReport.appliedAmount &&
                record.status === "PROCESSED" && (
                  <TextField
                    disabled
                    value={moneyFormat(
                      //@ts-ignore
                      (record?.contributionReport as CashInformation)
                        .appliedAmount
                    )}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Applied amount"
                  />
                )}
              <TextField
                disabled
                //@ts-ignore
                value={record?.contributionInformation?.paymentMethod}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Payment method"
              />
            </>
          )}
          {record?.type === "SECURITIES" && (
            <>
              <TextField
                disabled
                //@ts-ignore
                value={record?.contributionInformation?.securityName}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Security Name"
              />
              <TextField
                disabled
                //@ts-ignore
                value={record?.contributionInformation?.ticker}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Ticker"
              />
              <TextField
                disabled
                //@ts-ignore
                value={record?.contributionInformation?.quantity}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Quantity"
              />

              {record?.contributionInformation &&
                (record?.contributionInformation as SecuritiesInformation)
                  .estimatedMarketRate && (
                  <TextField
                    disabled
                    //@ts-ignore
                    value={moneyFormat(
                      //@ts-ignore
                      record?.contributionInformation.estimatedMarketRate
                    )}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Estimated Market Rate"
                  />
                )}
              {record?.contributionInformation &&
                (record?.contributionInformation as SecuritiesInformation)
                  .estimatedAmount && (
                  <TextField
                    disabled
                    value={moneyFormat(
                      //@ts-ignore
                      record?.contributionInformation.estimatedAmount
                    )}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Estimated Amount"
                  />
                )}

              {record?.contributionReport &&
                record?.contributionReport.marketRate &&
                record.status === "PROCESSED" && (
                  <TextField
                    disabled
                    //@ts-ignore
                    value={record?.contributionReport.marketRate}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Market Rate"
                  />
                )}

              {record?.contributionReport &&
                record?.contributionReport.appliedAmount &&
                record.status === "PROCESSED" && (
                  <TextField
                    disabled
                    //@ts-ignore
                    value={moneyFormat(
                      //@ts-ignore
                      record?.contributionReport.appliedAmount
                    )}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Applied Amount"
                  />
                )}
            </>
          )}
          {record?.type === "OTHER" && (
            <>
              <TextField
                disabled
                //@ts-ignore
                value={record?.contributionInformation?.description}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Description"
              />
              {(record?.contributionInformation as OtherAssetsInformation)
                .estimatedAmount && (
                <TextField
                  disabled
                  //@ts-ignore
                  value={record?.contributionInformation?.estimatedAmount}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Estimated amount"
                />
              )}
              {record?.contributionReport.appliedAmount &&
                record.status === "PROCESSED" && (
                  <TextField
                    disabled
                    value={moneyFormat(
                      //@ts-ignore
                      record?.contributionReport.appliedAmount
                    )}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Applied amount"
                  />
                )}
            </>
          )}
          {record?.status === "REJECTED" && record.rejectionReason && (
            <TextField
              disabled
              value={record.rejectionReason}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Rejection reason "
            />
          )}
          {record?.reference && (
            <TextField
              disabled
              value={record.reference}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="textarea"
              label="Reference "
              minRows={3}
              rows={2}
              maxRows={4}
              multiline
            />
          )}
          {record?.extraNotes && (
            <TextField
              disabled
              value={record.extraNotes}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Note"
              type="textarea"
              minRows={5}
              rows={2}
              maxRows={4}
              multiline
            />
          )}
          {record?.status === "PENDING" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",

                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <EvaluateContributionTransactionModal
                visible={open}
                close={setOpen}
                data={decision}
              />

              <Button
                size="large"
                variant="contained"
                style={{ marginRight: "0.5rem" }}
                color="primary"
                onClick={() => {
                  setOpen(true);
                  setDecision({
                    contributionTransaction: record,
                    value: "ACCEPTED",
                  });
                }}
              >
                Accept
              </Button>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setOpen(true);
                  setDecision({
                    contributionTransaction: record,
                    value: "REJECTED",
                  });
                }}
              >
                Reject
              </Button>
            </Box>
          )}
          {record?.status === "ACCEPTED" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",

                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <ProcessContributionTransactionModal
                visible={openProcess}
                close={setOpenProcess}
                contributionTransaction={record}
              />

              <Button
                size="large"
                variant="contained"
                style={{ marginRight: "0.5rem" }}
                color="primary"
                onClick={() => {
                  setOpenProcess(true);
                  setDecision({
                    contributionTransaction: record,
                    value: "ACCEPTED",
                  });
                }}
              >
                Process
              </Button>
            </Box>
          )}{" "}
        </>
      )}
    </Show>
  );
};
