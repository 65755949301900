import { CategoryList, CategoryCreate } from "pages/interest/categories";
import { Layout } from "components/layout";
import {
  UserList,
  UserDetails,
  UserRequestDetails,
  UserRequestList,
} from "pages/user";
import { Outlet, Route, Routes } from "react-router-dom";
import { NavigateToResource } from "@refinedev/react-router-v6";
import { TagCreate, TagList } from "pages/interest/tags";
import { UserRejectedList } from "pages/user/requests/rejected-list";
import { GrantVestmentCreate, GrantVestmentList } from "pages/grant-vestments";
import { GrantVestmentDetails } from "pages/grant-vestments/show";
import { ContributionTransactionCreate } from "pages/contribution-transaction/create";
import { ContributionTransactionDetails } from "pages/contribution-transaction/show";
import { FundAccountList } from "pages/fund-accounts/list";
import {
  ApprovedGrantVestmentRequestList,
  PendingGrantVestmentRequestList,
  RejectedGrantVestmentRequestList,
} from "pages/grant-vestments-request";
import { ContributionTransactionList } from "pages/contribution-transaction/accepted-list";
import { PendingContributionTransactionList } from "pages/contribution-transaction/pending-list";
import { RejectedContributionTransactionList } from "pages/contribution-transaction/rejected-list";
import { CanceledContributionTransactionList } from "pages/contribution-transaction/canceled-list";
import { GrantVestmentUpdate } from "pages/grant-vestments/update";
import { CharitiesList } from "pages/charities/list";
import { ProcessedContributionTransactionList } from "pages/contribution-transaction/processes-list";
import { InvestmentsList } from "pages/investments/list";
import { InvestmentRequestsPendingList } from "pages/investments-requests/pending-list";
import { InvestmentRequestsAcceptedList } from "pages/investments-requests/accepted-list";
import { InvestmentRequestsRejectedList } from "pages/investments-requests/rejected-list";
import { InvestmentUpdate } from "pages/investments/update";
import { InvestmentCreate } from "pages/investments/create";
import { InvestmentRequestDetails } from "pages/investments-requests/show";
import { GrantRequestsList } from "pages/grant-requests/list";
import { GRANT_REQUEST_STATUS_ENUM } from "interfaces/grant-requests";
import { GrantRequestDetails } from "pages/grant-requests/show";
import { FundAccountDetails } from "pages/fund-accounts/fund-details/show";
import { UpdateCategory } from "pages/interest/categories/edit";
import { UpdateTag } from "pages/interest/tags/edit";
import { ProcessedGrantVestmentRequestList } from "pages/grant-vestments-request/processed-list";
import { BatchUpdate } from "pages/investments/batch-update";
import { Home } from "pages/home";
import { NetaSetting } from "pages/neta-settings";
import { RecommendedCharitiesPendingList } from "pages/recommended-charities /pending-list";
import { RecommendedCharitiesApprovedList } from "pages/recommended-charities /approved-list";
import { RecommendedCharitiesDetails } from "pages/recommended-charities /show";

const AppRouter = () => {
  return (
    <Routes>
      <Route
        element={
          <Layout>
            <Outlet />
          </Layout>
        }
      >
        <Route index element={<Home />} />
        <Route path="neta-settings" index element={<NetaSetting />} />
        <Route index element={<NavigateToResource resource="list" />} />
        <Route index element={<NavigateToResource resource="status" />} />
        <Route index element={<NavigateToResource resource="categories" />} />

        <Route
          index
          element={<NavigateToResource resource="grant-vestments" />}
        />
        <Route
          index
          element={<NavigateToResource resource="contribution-transactions" />}
        />
        <Route index element={<NavigateToResource resource="logout" />} />

        <Route path="users" element={<Outlet />}>
          <Route index path="status/accepted" element={<UserList />} />
          <Route index path="show/:sub" element={<UserDetails />} />

          <Route path="status" element={<Outlet />}>
            <Route index path="Pending" element={<UserRequestList />} />
            <Route index path=":sub" element={<UserRequestDetails />} />
          </Route>
          <Route index path="status/rejected" element={<UserRejectedList />} />
        </Route>

        <Route path="categories">
          <Route index element={<CategoryList />} />
          <Route path="create" element={<CategoryCreate />} />
          <Route path=":id/edit" element={<UpdateCategory />} />
        </Route>

        <Route path="tags">
          <Route index element={<TagList />} />
          <Route path="create" element={<TagCreate />} />
          <Route path=":id/edit" element={<UpdateTag />} />
        </Route>

        <Route path="fund-accounts">
          <Route index path="list" element={<FundAccountList />} />
          <Route index path="show/:id" element={<FundAccountDetails />} />
        </Route>

        <Route path="grant-vestments">
          <Route index path="list" element={<GrantVestmentList />} />
          <Route path="update/:id" element={<GrantVestmentUpdate />} />
          <Route path="create" element={<GrantVestmentCreate />} />
          <Route path=":id" element={<GrantVestmentDetails />} />
        </Route>

        <Route path="contribution-transactions">
          <Route index element={<ContributionTransactionList />} />
          <Route
            path="status/processed"
            index
            element={<ProcessedContributionTransactionList />}
          />
          <Route
            path="status/pending"
            index
            element={<PendingContributionTransactionList />}
          />
          <Route
            path="status/rejected"
            index
            element={<RejectedContributionTransactionList />}
          />
          <Route
            path="status/canceled"
            index
            element={<CanceledContributionTransactionList />}
          />
          <Route path="create" element={<ContributionTransactionCreate />} />
          <Route
            path=":status/:id"
            element={<ContributionTransactionDetails />}
          />
        </Route>

        <Route path="grant-vestments-requests">
          <Route
            index
            path="status/approved"
            element={<ApprovedGrantVestmentRequestList />}
          />
          <Route
            index
            path="status/processed"
            element={<ProcessedGrantVestmentRequestList />}
          />
          <Route
            index
            path="status/pending"
            element={<PendingGrantVestmentRequestList />}
          />
          <Route
            index
            path="status/rejected"
            element={<RejectedGrantVestmentRequestList />}
          />
        </Route>

        <Route path="charities">
          <Route index path="list" element={<CharitiesList />} />
        </Route>

        <Route path="investments">
          <Route index path="list" element={<InvestmentsList />} />
          <Route index path="history" element={<BatchUpdate />} />
          <Route path="create" element={<InvestmentCreate />} />
          <Route path="edit/:id" element={<InvestmentUpdate />} />
        </Route>
        <Route path="investment-requests">
          <Route
            index
            path="status/pending"
            element={<InvestmentRequestsPendingList />}
          />
          <Route
            index
            path="status/accepted"
            element={<InvestmentRequestsAcceptedList />}
          />
          <Route
            index
            path="status/rejected"
            element={<InvestmentRequestsRejectedList />}
          />
          <Route path=":status/:id" element={<InvestmentRequestDetails />} />
        </Route>
        <Route path="recommended-charities">
          <Route
            index
            path="status/pending"
            element={<RecommendedCharitiesPendingList />}
          />
          <Route
            index
            path="status/approved"
            element={<RecommendedCharitiesApprovedList />}
          />
          <Route path=":status/:id" element={<RecommendedCharitiesDetails />} />
        </Route>
        <Route path="grant-requests">
          <Route
            index
            path="status/pending"
            element={
              <GrantRequestsList status={GRANT_REQUEST_STATUS_ENUM.PENDING} />
            }
          />
          <Route
            index
            path="status/approved"
            element={
              <GrantRequestsList status={GRANT_REQUEST_STATUS_ENUM.APPROVED} />
            }
          />
          <Route
            index
            path="status/rejected"
            element={
              <GrantRequestsList status={GRANT_REQUEST_STATUS_ENUM.REJECTED} />
            }
          />
          <Route
            index
            path="status/canceled"
            element={
              <GrantRequestsList status={GRANT_REQUEST_STATUS_ENUM.CANCELED} />
            }
          />
          <Route
            index
            path="status/processed"
            element={
              <GrantRequestsList status={GRANT_REQUEST_STATUS_ENUM.PROCESSED} />
            }
          />
          <Route
            index
            path="status/stopped"
            element={
              <GrantRequestsList status={GRANT_REQUEST_STATUS_ENUM.STOPPED} />
            }
          />
          <Route
            index
            path="status/scheduled"
            element={
              <GrantRequestsList status={GRANT_REQUEST_STATUS_ENUM.SCHEDULED} />
            }
          />
          <Route path=":id" element={<GrantRequestDetails />} />
        </Route>

        <Route path="*" element={<div>Error!</div>} />
      </Route>
    </Routes>
  );
};
export default AppRouter;
