import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import { useCreate } from "@refinedev/core";
import { useState } from "react";

interface ModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fund: { fundName: string; fundId: number };
  refetch: any;
}

export const FeedModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  fund,
  refetch,
}) => {
  const [type, setType] = useState("CASH");
  const [amount, setAmount] = useState<{ value: number; error: boolean }>({
    value: 0,
    error: false,
  });
  const date = new Date().toISOString().substring(0, 10);
  const [distributionDate, setDistributionDate] = useState({
    value: date,
    error: false,
  });

  const { mutate, isLoading } = useCreate();

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle color="primary">
        Add fee for fund {fund.fundName}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography py={1}>Fee type </Typography>
        <Grid item xs={12} sm={10}>
          <ToggleButtonGroup
            fullWidth
            size="small"
            value={type}
            exclusive
            color="primary"
          >
            <ToggleButton
              fullWidth
              value={"CASH"}
              aria-label="centered"
              name="type"
              onClick={() => setType("CASH")}
            >
              <SvgIcon sx={{ mr: 2 }}>
                <AttachMoneyIcon />
              </SvgIcon>
              CASH
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Typography pt={1}>
          Please add the <b>{type} </b>amount
        </Typography>
        <TextField
          value={amount.value || 0}
          error={amount.error}
          helperText={amount.error && "The amount is required!"}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: type === "CASH" && (
              <InputAdornment position="start">$</InputAdornment>
            ),
            inputComponent: NumericFormatCustom as any,
          }}
          onChange={(e) =>
            setAmount({ value: parseFloat(e.target.value), error: false })
          }
          label={"Amount"}
          name="amount"
        />

        <Typography pt={2}>
          Please add the fee date
        </Typography>
        <Grid item xs={12}>
          <TextField
            id="referenceDate"
            error={distributionDate.error}
            helperText={distributionDate.error && "The date is required!"}
            defaultValue={distributionDate.value}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="date"
            label="Date"
            name="distributionDate"
            onChange={(event) => {
              setDistributionDate({ value: event.target.value, error: false });
            }}
          />
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          startIcon={
            isLoading && <CircularProgress size={20} color="inherit" />
          }
          variant="outlined"
          disabled={
            amount.error ||
            distributionDate.error ||
            isLoading ||
            amount.value <= 0 ||
            !amount.value
          }
          onClick={() => {
            if (amount.value <= 0 || !amount.value || !distributionDate.value) {
              if (amount.value <= 0 || !amount.value) {
                setAmount({ value: amount.value, error: true });
              }
              if (!distributionDate.value) {
                setDistributionDate({ ...distributionDate, error: true });
              }
            } else {
              mutate(
                {
                  resource: "fund-fees",
                  values: {
                    type: type,
                    amount: amount.value,
                    fundAccountId: fund.fundId,
                    referenceDate: distributionDate.value,
                  },
                },
                {
                  onSuccess: () => {
                    setAmount({ value: 0, error: false });
                    setDistributionDate({ value: date, error: false });
                    refetch();
                    setOpen(false);
                  },
                }
              );
            }
          }}
        >
          Confirm
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setAmount({ value: 0, error: false });
            setDistributionDate({ value: date, error: false });
            setOpen(false);
          }}
        >
          Cancel
        </Button>{" "}
      </DialogActions>
    </Dialog>
  );
};
