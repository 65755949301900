import { FC } from "react";
import { styled } from "@mui/material/styles";
import { accent, warm } from "style/color";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { ICharity } from "interfaces/charity";
import { useUpdate } from "@refinedev/core";
export const CustomCard = styled(Card)({
  "& :hover": {
    backgroundColor: warm[500],
  },
  "& :hover button": {
    backgroundColor: "transparent",
  },
});

interface CharityCardProps {
  charity: ICharity;
  refresh: () => void;
}

const CharityCard: FC<CharityCardProps> = ({ charity, refresh }) => {
  const { mutate } = useUpdate();
  const handleClick = (event: any) => {
    mutate(
      {
        resource: `charities`,
        values: {
          isBlocked: !charity.isBlocked,
        },
        id: charity.ein,
      },
      {
        onSuccess: () => {
          refresh();
        },
      }
    );
  };
  return (
    <CustomCard>
      <CardContent sx={{ "&:last-child": { pb: 5 } }}>
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography
              variant="smallCopy"
              sx={{
                color: "neutral.main",
              }}
            >
              {charity.ein}
            </Typography>
            <IconButton
              onClick={handleClick}
              className="charities-unsaved"
              size="small"
              sx={{ color: accent[500] }}
            >
              {charity.isBlocked ? (
                <VisibilityOffOutlinedIcon />
              ) : (
                <VisibilityOutlinedIcon />
              )}
            </IconButton>
          </Stack>
        </Box>
        <Box sx={{ minHeight: "120px" }}>
          <Typography
            gutterBottom
            sx={{
              color: "neutral.main",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              whiteSpace: "pre-wrap",
              height: "54px",
            }}
            variant="subtitle1"
          >
            {charity.name}
          </Typography>
          <Typography
            paragraph
            sx={{
              textTransform: "lowercase",
              color: "neutral.main",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              whiteSpace: "pre-wrap",
            }}
            variant="smallCopyBold"
          >
            {charity.mission}
          </Typography>
        </Box>
        <Typography
          paragraph
          sx={{
            color: "neutral.main",
          }}
          variant="smallCopyBold"
        >
          {charity.street} {charity.street2},<br></br>
          {charity.city}, {charity.state} {charity.zip}, {charity.country}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography
            variant="bodyCopyBold"
            sx={{
              color: "neutral.main",
            }}
          >
            {charity.cause}
          </Typography>
          <Button
            target="_blank"
            href={`https://${charity.organizationUrl}`}
            startIcon={<LaunchIcon />}
            variant="text"
            color="primary"
            className="charites-more-info"
          >
            More info
          </Button>
        </Stack>
      </CardContent>
    </CustomCard>
  );
};

export default CharityCard;
