import React from "react";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import { List, ShowButton, useDataGrid } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { IRecommendedCharity } from "interfaces/recommended-charity";

export const RecommendedCharitiesPendingList: React.FC<
  IResourceComponentsProps
> = () => {
  const { dataGridProps } = useDataGrid<IRecommendedCharity>({
    queryOptions: { enabled: false },
  });
  const { data, isLoading } = useCustom({
    url: `recommended-charity`,
    method: "get",
    config: {
      query: {},
      filters: [
        {
          field: "filter[status]",
          operator: "eq",
          value: "PENDING",
        },
      ],
    },
  });
  const navigate = useNavigate();
  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "recommendedCharityId",
        flex: 1,
        headerName: "ID",
        maxWidth: 100,
        renderCell: function render({ value }: any) {
          return value.recommendedCharityId;
        },
      },
      {
        field: "user",
        flex: 1,
        headerName: "User",
        minWidth: 200,
        renderCell: function render({ value }: any) {
          return `${value.firstName} ${value.lastName}`;
        },
      },
      {
        field: "charityName",
        flex: 1,
        headerName: "Charity Name",
        maxWidth: 200,
      },
      {
        field: "ein",
        flex: 1,
        headerName: "Ein",
        maxWidth: 200,
      },
      {
        field: "contactName",
        flex: 1,
        headerName: "Contact Name",
        maxWidth: 200,
      },
      {
        field: "contactEmail",
        flex: 1,
        headerName: "Contact Email",
        maxWidth: 200,
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <ShowButton
              hideText
              onClick={() =>
                navigate(
                  `/recommended-charities/pending/${row.recommendedCharityId}`
                )
              }
            />
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [navigate]
  );

  return (
    <List title="Pending Recommended charities">
      {!isLoading && (
        <DataGrid
          {...dataGridProps}
          rows={data?.data?.data}
          getRowId={(row) => row.recommendedCharityId}
          columns={columns}
          autoHeight
          loading={isLoading}
        />
      )}
    </List>
  );
};
