import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import React from "react";
import { Edit } from "@refinedev/mui";
export const NetaSetting: React.FC<IResourceComponentsProps> = () => {
  const { data: netaData, isLoading } = useCustom({
    url: `neta-settings`,
    method: "get",
  });
  const {
    refineCore: { formLoading, onFinish },
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      errorNotification: (error): any => {
        return {
          message:
            error?.message && "Oops! something went wrong, please try later.",
          type: "error",
        };
      },
    },

    warnWhenUnsavedChanges: true,
  });

  return (
    <Edit
      title={
        <Typography variant="body1" fontWeight="bold">
          Update Neta settings
        </Typography>
      }
      isLoading={formLoading}
      saveButtonProps={{
        onClick: (e: React.BaseSyntheticEvent) => {
          handleSubmit(onFinish, () => false)(e);
        },
      }}
    >
      <Grid>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Grid style={{ backgroundColor: "white", padding: 10 }}>
            <TextField
              {...register("name")}
              error={!!(errors as any)?.name}
              helperText={(errors as any)?.name?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.name!}
              type="text"
              name="name"
              label="Name"
              disabled
            />
            <TextField
              {...register("ein")}
              error={!!(errors as any)?.ein}
              helperText={(errors as any)?.ein?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.ein!}
              type="text"
              name="name"
              label="Ein"
              disabled
            />
            <TextField
              {...register("phoneNumber", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.phoneNumber}
              helperText={(errors as any)?.phoneNumber?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.phoneNumber!}
              type="text"
              name="phoneNumber"
              label="Phone Number"
            />
            <TextField
              {...register("personContact", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.personContact}
              helperText={(errors as any)?.personContact?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.personContact!}
              type="text"
              name="personContact"
              label="Person to contact"
            />
            <TextField
              {...register("supportEmail", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.supportEmail}
              helperText={(errors as any)?.supportEmail?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.supportEmail!}
              type="text"
              name="supportEmail"
              label="Support Email"
            />
            <TextField
              {...register("notificationEmail", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.notificationEmail}
              helperText={(errors as any)?.notificationEmail?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.notificationEmail!}
              type="text"
              name="notificationEmail"
              label="Notification Email"
            />
            <TextField
              {...register("ccEmail", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.ccEmail}
              helperText={(errors as any)?.ccEmail?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.ccEmail!}
              type="text"
              name="ccEmail"
              label="Cc Email"
            />
            <TextField
              {...register("addressLine1", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.addressLine1}
              helperText={(errors as any)?.addressLine1?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.addressLine1!}
              type="text"
              name="addressLine1"
              label="Address Line1"
            />
            <TextField
              {...register("addressLine2", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.addressLine2}
              helperText={(errors as any)?.addressLine2?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.addressLine2!}
              type="text"
              name="addressLine2"
              label="Address Line2"
            />
            <TextField
              {...register("receivingBankAccount", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.receivingBankAccount}
              helperText={(errors as any)?.receivingBankAccount?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.receivingBankAccount!}
              type="text"
              name="receivingBankAccount"
              label="Receiving Bank Account"
            />
            <TextField
              {...register("receivingBankAddressLine1", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.receivingBankAddressLine1}
              helperText={(errors as any)?.receivingBankAddressLine1?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.receivingBankAddressLine1!}
              type="text"
              name="receivingBankAddressLine1"
              label="Receiving Bank Address Line1"
            />
            <TextField
              {...register("receivingBankAddressLine2", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.receivingBankAddressLine2}
              helperText={(errors as any)?.receivingBankAddressLine2?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.receivingBankAddressLine2!}
              type="text"
              name="receivingBankAddressLine2"
              label="Receiving Bank AddressLine2"
            />
            <TextField
              {...register("ABA", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.ABA}
              helperText={(errors as any)?.ABA?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.ABA!}
              type="text"
              name="ABA"
              label="ABA"
            />
            <TextField
              {...register("brokerName", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.brokerName}
              helperText={(errors as any)?.brokerName?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.brokerName!}
              type="text"
              name="brokerName"
              label="Broker Name"
            />
            <TextField
              {...register("DTCNumber", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.DTCNumber}
              helperText={(errors as any)?.DTCNumber?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.DTCNumber!}
              type="text"
              name="DTCNumber"
              label="DTC Number"
            />
            <TextField
              {...register("accountNumber", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.accountNumber}
              helperText={(errors as any)?.accountNumber?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.accountNumber!}
              type="text"
              name="accountNumber"
              label="Account Number"
            />
            <TextField
              {...register("contributionMinimumAmount", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.contributionMinimumAmount}
              helperText={(errors as any)?.contributionMinimumAmount?.message}
              margin="normal"
              fullWidth
              style={{ marginTop: 10 }}
              InputLabelProps={{ shrink: true }}
              defaultValue={netaData?.data.contributionMinimumAmount!}
              type="text"
              name="contributionMinimumAmount"
              label="Contribution Minimum Amount"
            />

            {/* --- For future implementation
            <TextField
            {...register("grantVestmentThreshold", {
              required: "This field is required",
            })}
            error={!!(errors as any)?.grantVestmentThreshold}
            helperText={(errors as any)?.grantVestmentThreshold?.message}
            margin="normal"
            fullWidth
            style={{ marginTop: 10 }}
            InputLabelProps={{ shrink: true }}
            defaultValue={netaData?.data.grantVestmentThreshold!}
            type="text"
            name="grantVestmentThreshold"
            label="GrantVestment Threshold"
          /> */}
          </Grid>
        )}{" "}
      </Grid>
    </Edit>
  );
};
