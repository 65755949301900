import { Alert, CircularProgress, Divider, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useUpdate } from "@refinedev/core";
import {
} from "interfaces/contribution-transaction";
import { IGrantRequest, OnTimeSchedule } from "interfaces/grant-requests";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  visible: boolean;
  data: {
    grantRequest: IGrantRequest | undefined;
    value: "PENDING" | "APPROVED" | "REJECTED" | "PROCESSED";
  };
  close: React.Dispatch<React.SetStateAction<boolean>>;
}
export const EvaluateGrantRequestModal: React.FC<ModalProps> = ({
  visible,
  close,
  data,
}) => {
  const [rejectionReason, setRejectionReason] = useState("");

  const [error, setError] = useState(false);
  const [pendingRequestError, setPendingRequestError] = useState("");
  const { mutate, isLoading } = useUpdate();
  const navigate = useNavigate();
  return (
    <Dialog open={visible} onClose={() => close(false)}>
      <DialogTitle color="primary">

        {data.value === "APPROVED" ? "Accept" : data.value === "REJECTED" ? "Reject" : "Process"} this grant request
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Are you sure you want to{" "}
          {data.value === "APPROVED" ? "Accept" : data.value === "REJECTED" ? "Reject" : "Process"} this user
          grant request?
        </Typography>
        {data.value === "REJECTED" && (
          <>
            <TextField
              value={rejectionReason}
              error={error}
              helperText={error && "The rejection reason is required"}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Rejection reason"
              name="rejectionReason"
              multiline={true}
              rows={3}
              onChange={(e) => { setError(false); setRejectionReason(e.target.value) }}
            />


          </>)}
        {
          pendingRequestError !== "" && (
            <Alert sx={{
              mt: 1
            }} severity="error">{pendingRequestError}.</Alert>
          )
        }
      </DialogContent>
      <Divider />
      <DialogActions>
        <>
          <Button
            variant="contained"
            onClick={() => {
              if (!rejectionReason && data.value === "REJECTED") {
                setError(true);
              } else {
                setPendingRequestError("")
                mutate(
                  {
                    resource: "grant-requests",
                    values:
                      data.value === "APPROVED" || data.value === "PROCESSED"
                        ? {
                          status: data.value,
                        }
                        : {
                          rejectionReason: rejectionReason,
                          status: data.value,
                        },
                    id: data.grantRequest?.grantRequestId as any,
                  },
                  {
                    onSuccess: () => {
                      if (
                        data.value === "APPROVED"
                      ) {
                        if (
                          (data.grantRequest?.type === "ONETIME" &&
                            !(data.grantRequest?.scheduleInformation as OnTimeSchedule).now) || data.grantRequest?.type === "RECURRENT"
                        ) {
                          navigate("/grant-requests/status/scheduled");
                        }
                        else {
                          navigate("/grant-requests/status/approved");

                        }
                      }
                      if (data.value === "PROCESSED") {
                        navigate("/grant-requests/status/processed");
                      }
                      if (data.value === "REJECTED") {
                        navigate("/grant-requests/status/rejected");
                      }

                      setError(false);
                      close(false);
                    },
                    onError: (error) => {
                      setPendingRequestError(error.message)
                    }
                  }
                );
              }
            }}
            disabled={isLoading}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              close(false);
              setError(false);
            }}
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
