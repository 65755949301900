import {
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import { moneyFormat } from "utils/money-format";
import { useCreate } from "@refinedev/core";

interface ModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ticker: string;
}

export const DividendModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  ticker,
}) => {
  const [type, setType] = useState("CASH");
  const [amount, setAmount] = useState({ value: 0, error: false });
  const [reinvest, setReinvest] = useState(false);
  const date = new Date().toISOString().substring(0, 10);
  const [distributionDate, setDistributionDate] = useState({
    value: date,
    error: false,
  });
  const [nbReinvestedUnits, setNbReinvestedUnits] = useState({
    value: 0,
    error: false,
  });
  const { mutate, isLoading } = useCreate();

  useEffect(() => {
    if (type !== "CASH") {
      setReinvest(false);
    }
  }, [type]);
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle color="primary">
        Add dividend for investment {ticker}{" "}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography py={1}>Please choose dividend type </Typography>
        <Grid item xs={12} sm={10}>
          <ToggleButtonGroup
            fullWidth
            size="small"
            value={type}
            exclusive
            color="primary"
          >
            <ToggleButton
              fullWidth
              value={"UNIT"}
              aria-label="left aligned"
              name="type"
              onClick={() => setType("UNIT")}
            >
              <SvgIcon sx={{ mr: 2 }}>
                <TrendingUpIcon />
              </SvgIcon>
              UNIT
            </ToggleButton>
            <ToggleButton
              fullWidth
              value={"CASH"}
              aria-label="centered"
              name="type"
              onClick={() => setType("CASH")}
            >
              <SvgIcon sx={{ mr: 2 }}>
                <AttachMoneyIcon />
              </SvgIcon>
              CASH
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Typography pt={1}>
          Please add the <b>{type} </b>amount
        </Typography>
        <TextField
          value={amount.value || 0}
          error={amount.error}
          helperText={amount.error && "The amount is required!"}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: type === "CASH" && (
              <InputAdornment position="start">$</InputAdornment>
            ),
            inputComponent: NumericFormatCustom as any,
          }}
          onChange={(e) =>
            setAmount({ value: parseFloat(e.target.value), error: false })
          }
          label={type === "CASH" ? "Amount" : "Number of units"}
          name="amount"
        />
        <Typography py={1}>
          Do you want to <b>reinvest</b> the <b>{moneyFormat(amount.value)}</b>
        </Typography>
        <Grid item xs={12} sm={10}>
          <ToggleButtonGroup
            fullWidth
            size="small"
            value={reinvest ? "reinvest" : "distribute"}
            exclusive
            color="primary"
          >
            <ToggleButton
              fullWidth
              value={"reinvest"}
              aria-label="left aligned"
              name="reinvest"
              disabled={type === "UNIT"}
              onClick={() => setReinvest(true)}
            >
              <SvgIcon sx={{ mr: 2 }}>
                <TrendingUpIcon />
              </SvgIcon>
              Yes, Reinvest them
            </ToggleButton>
            <ToggleButton
              fullWidth
              value={"distribute"}
              aria-label="centered"
              name="reinvest"
              onClick={() => setReinvest(false)}
            >
              <SvgIcon sx={{ mr: 2 }}>
                <AttachMoneyIcon />
              </SvgIcon>
              No, Distribute them
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {reinvest && (
          <>
            <Typography pt={2}>
              Please add the number of reinvested units
            </Typography>
            <Grid item xs={12}>
              <TextField
                id="nbReinvestedUnits"
                name="nbReinvestedUnits"
                label="Number of units"
                error={nbReinvestedUnits.error}
                helperText={
                  nbReinvestedUnits.error && "The number of units is required!"
                }
                value={nbReinvestedUnits.value || 0}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                }}
                onChange={(e) => {
                  setNbReinvestedUnits({
                    value: parseFloat(e.target.value),
                    error: false,
                  });
                }}
              />
            </Grid>
          </>
        )}
        <Typography pt={2}>
          Please add the dividend distribution date
        </Typography>
        <Grid item xs={12}>
          <TextField
            id="referenceDate"
            error={distributionDate.error}
            helperText={
              distributionDate.error &&
              "The date is required, and can not be negative value!"
            }
            defaultValue={distributionDate.value}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="date"
            label="Date"
            name="distributionDate"
            onChange={(event) => {
              setDistributionDate({ value: event.target.value, error: false });
            }}
          />
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <>
          <Button
            variant="contained"
            disabled={
              amount.value === 0 || (reinvest && nbReinvestedUnits.value <= 0) || isLoading
            }
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            onClick={() => {
              if (amount.value === 0 || !amount.value) {
                setAmount({ ...amount, error: true });
              }
              if (!distributionDate.value) {
                setDistributionDate({ ...distributionDate, error: true });
              }
              if (reinvest && nbReinvestedUnits.value <= 0) {
                setNbReinvestedUnits({ ...nbReinvestedUnits, error: true });
              }
              if (
                !amount.error &&
                !nbReinvestedUnits.error &&
                !distributionDate.error
              ) {
                mutate({
                  resource: "fund-dividend-distribution",
                  values: {
                    type: type,
                    amount: amount.value,
                    ticker: ticker,
                    reinvest: reinvest,
                    distributionDate: distributionDate.value,
                    nbReinvestedUnits: nbReinvestedUnits.value,
                  },
                });
                setAmount({ value: 0, error: false });
                setDistributionDate({ value: date, error: false });
                setNbReinvestedUnits({ value: 0, error: false });
                setOpen(false);
              }
            }}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setAmount({ value: 0, error: false });
              setDistributionDate({ value: date, error: false });
              setNbReinvestedUnits({ value: 0, error: false });
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
