export enum SUCCESSOR_TYPE_ENUM {
  CHARITY = "CHARITY",
  INDIVIDUAL = "INDIVIDUAL",
}
export interface IndividualInfo {
  title?: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  suffix?: string;
  email: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: number;
  phoneNumber?: string;
}
export interface CharityInfo {
  ein: string;
  name: string;
  specificInstruction?: string;
}
export interface ISuccessor {
  successorId: number;
  successorType: SUCCESSOR_TYPE_ENUM;
  successorInfo: CharityInfo | IndividualInfo;
  percentage: number;
}
