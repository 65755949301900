import React, { useState } from "react";
import { useDataGrid, List, DateField } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import { IGrantVestmentRequest } from "interfaces/grant-vestment-request";
import { moneyFormat } from "utils/money-format";
import { Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { EvaluateGrantVestmentRequestModal } from "components/modal/evaluate-grant-vestment-request";

export const ApprovedGrantVestmentRequestList: React.FC<
  IResourceComponentsProps
> = () => {
  const { dataGridProps } = useDataGrid<IGrantVestmentRequest>({
    queryOptions: { enabled: false },
  });
  const { data, isLoading } = useCustom({
    url: `grant-vestment-requests`,
    method: "get",
    config: {
      filters: [
        {
          field: "filter[status]",
          operator: "eq",
          value: "APPROVED",
        },
      ],
    },
  });
  const [open, setOpen] = useState(false);
  const [decision, setDecision] = useState<{
    grantvestmentRequest: IGrantVestmentRequest | undefined;
    value: "PENDING" | "APPROVED" | "REJECTED" | "PROCESSED";
  }>({ grantvestmentRequest: undefined, value: "PENDING" });

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "grantVestmentRequestId",
        flex: 1,
        headerName: "ID",
        maxWidth: 50,
        renderCell: function render({ value }: any) {
          return value.name;
        },
      },
      {
        field: "grantVestment",
        flex: 1,
        headerName: "Grant vestment title",

        renderCell: function render({ value }: any) {
          return value.name;
        },
      },
      {
        field: "fund",
        flex: 1,
        headerName: "Fund Name",

        renderCell: function render({ value }: any) {
          return value.fundAccountName;
        },
      },
      {
        field: "user",
        flex: 1,
        headerName: "Advisor",

        renderCell: function render({ value }: any) {
          return `${value.firstName} ${value.lastName}`;
        },
      },
      {
        field: "appliedAmount",
        flex: 1,
        headerName: "Applied Amount",

        renderCell: function render({ value }: any) {
          return <>${moneyFormat(value)}</>;
        },
      },
      {
        field: "status",
        flex: 1,
        headerName: "Status",
      },
      {
        field: "createdAt",
        flex: 1,
        headerName: "Created Date",

        renderCell: function render({ value }: any) {
          return <DateField value={value} />;
        },
      },
      {
        field: "updatedAt",
        flex: 1,
        headerName: "Accepting Date",

        renderCell: function render({ value }: any) {
          return <DateField value={value} />;
        },
      },
      {
        field: "actions",
        headerName: "Process",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              {" "}
              <Button
                variant="contained"
                onClick={() => {
                  setDecision({
                    grantvestmentRequest: row,
                    value: "PROCESSED",
                  });
                  setOpen(true);
                }}
              >
                <CheckIcon />
              </Button>
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    []
  );

  return (
    <>
      <List title="Approved GrantVestment Request List">
        {!isLoading && (
          <DataGrid
            {...dataGridProps}
            rows={data?.data?.data}
            getRowId={(row) => row.grantVestmentRequestId}
            columns={columns}
            autoHeight
            loading={isLoading}
          />
        )}
      </List>
      <EvaluateGrantVestmentRequestModal
        visible={open}
        close={setOpen}
        data={decision}
      />
    </>
  );
};
