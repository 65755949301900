import { Divider } from "@aws-amplify/ui-react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useCreate } from "@refinedev/core";
import { useEffect, useState } from "react";
interface UpdateMarketValueModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  grantvestmentId: number;
  currentValue: number;
  grantvestmentMoic: number;
  raisedAmount: number;
  refetch: any;
}
export const UpdateMarketValueModal: React.FC<UpdateMarketValueModalProps> = ({
  setOpen,
  open,
  grantvestmentId,
  currentValue,
  raisedAmount,
  grantvestmentMoic,
  refetch,
}) => {
  const [value, setValue] = useState(currentValue);
  const [moicValue, setMoicValue] = useState(0);
  const [valueError, setValueError] = useState(false);

  const { mutate: addMarketRate, isLoading } = useCreate();

  useEffect(() => setMoicValue(grantvestmentMoic), [grantvestmentMoic]);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Update Market Value</DialogTitle>
        <Divider />
        <DialogContent sx={{ paddingX: "10px" }}>
          <DialogContentText>
            Please enter the new market value
          </DialogContentText>
          <TextField
            margin="normal"
            value={raisedAmount}
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="number"
            label="Initial Raised Amount"
            disabled
            name="initial"
          />
          <TextField
            margin="normal"
            value={currentValue}
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setValue(parseInt(e.target.value))}
            type="number"
            label="Current value"
            disabled
            name="currentValue"
          />
          <TextField
            value={moicValue}
            margin="normal"
            fullWidth
            disabled
            InputLabelProps={{ shrink: true }}
            type="number"
            label="MOIC"
            name="moic"
          />{" "}
          <TextField
            error={valueError}
            helperText={valueError && "The new value is required"}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              let value = parseInt(e.target.value);
              setValue(value);
              let moic = value / raisedAmount;
              setMoicValue(Math.round((moic + Number.EPSILON) * 10) / 10);
            }}
            type="number"
            label="New value"
            name="newValue"
          />{" "}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            onClick={() => {
              if (!value) {
                setValueError(true);
              } else {
                addMarketRate(
                  {
                    resource: "market-value-history",
                    values: {
                      grantVestmentId: grantvestmentId,
                      value: value,
                      moicValue: moicValue,
                    },
                  },
                  {
                    onSuccess: () => {
                      setValueError(false);
                      setOpen(false);
                      setValue(0);
                      refetch();
                    },
                  }
                );
              }
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
