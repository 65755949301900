import { CircularProgress, Divider, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useUpdate } from "@refinedev/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  visible: boolean;
  decision: { sub: string; value: "PENDING" | "ACCEPTED" | "REJECTED" };
  close: React.Dispatch<React.SetStateAction<boolean>>;
}
export const DecideRequestModal: React.FC<ModalProps> = ({
  visible,
  close,
  decision,
}) => {
  const [reason, setReason] = useState("");
  const { mutate, isLoading } = useUpdate();
  const navigate = useNavigate();

  return (
    <Dialog open={visible} onClose={() => close(false)}>
      <DialogTitle color="primary">
        {decision.value === "ACCEPTED" ? "Accept" : "Reject"} user request
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Are you sure you want to{" "}
          {decision.value === "ACCEPTED" ? "accept" : "reject"} this user
          request?
        </Typography>

        {decision.value === "REJECTED" && (
          <TextField
            value={reason}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            multiline={true}
            rows={3}
            label="Rejection reason"
            name="reason"
            onChange={(e) => setReason(e.target.value)}
          />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <>
          <Button
            variant="contained"
            disabled={isLoading}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            onClick={() => {
              mutate(
                {
                  resource: "users/handelUserRequest",
                  values: {
                    status: decision.value,
                    reason: reason,
                  },
                  id: decision.sub,
                },
                {
                  onSuccess: () => {
                    if (decision.value === "ACCEPTED") {
                      navigate("/users/status/accepted");
                    } else {
                      navigate("/users/status/rejected");
                    }
                  },
                }
              );
              close(false);
            }}
          >
            Confirm
          </Button>
          <Button variant="outlined" onClick={() => close(false)}>
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
