import { PropsWithChildren } from "react";
import { ThemedTitleV2, ThemedLayoutV2, Sider } from "@refinedev/mui";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  listItemClasses,
} from "@mui/material";
import logo from "../../assets/logo-s.svg";
import logoSmall from "../../assets/logo-small.svg";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const { signOut } = useAuthenticator();
  const navigate = useNavigate();
  return (
    <ThemedLayoutV2
      initialSiderCollapsed={false}
      Title={({ collapsed }) => <ThemedTitleV2 collapsed={collapsed} />}
      Sider={() => (
        <Sider
          Title={({ collapsed }) => (
            <span
              style={{
                padding: "11px",
                borderBlockEndStyle: "solid",
                borderBlockWidth: "1px",
                borderBlockColor: "#F7F3F1",
              }}
            >
              {collapsed ? (
                <img alt="Logo" src={logoSmall} />
              ) : (
                <Button
                  size="large"
                  sx={{
                    [` & .${listItemClasses.root}:hover`]: {
                      backgroundColor: "transparent",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => navigate("/")}
                >
                  <Box component="img" alt="Logo" src={logo} />
                </Button>
              )}
            </span>
          )}
        />
      )}
      Header={() => (
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              color={"white"}
              sx={{ flexGrow: 1 }}
            >
              <Button
                size="large"
                sx={{ color: "white" }}
                onClick={() => navigate("/")}
              >
                Dashboard
              </Button>
            </Typography>
            <Button
              size="large"
              variant="outlined"
              color="secondary"
              sx={{
                borderRadius: "30px",
                color: "white",
                backgroundColor: "transparent",
              }}
              onClick={() => signOut()}
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      )}
    >
      {children}
    </ThemedLayoutV2>
  );
};
